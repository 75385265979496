import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import S3PaymentModal from './S3PaymentModel';
import { handlePayment } from '../razorpay';
import './purchaceFlow.css';
import axios from 'axios';
import msicon2 from '../Assets/msicon2.svg';
import Card from '../../components/itemCard';
import Notification from "../../components/Notification";


const ReviewPlaceOrder = ({ onAction, countryData }) => {
    const [showPopup, setShowPopup] = useState(false);
    const { id } = useParams();
    const [country, setCountry] = useState(countryData?.country || ''); // Initialize with countryData or empty string
    const [orderDetails, setOrderDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isChecked, setIsChecked] = useState(false);
    const [showRetryButton, setShowRetryButton] = useState(false);
    const [captureDetails, setCaptureDetails] = useState(null);
    const [retryUrl, setRetryUrl] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [service, setService] = useState(null);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [authChecked, setAuthChecked] = useState(false);
    const [message, setMessage] = useState('');
    const token = localStorage.getItem('token');
    const name = localStorage.getItem('firstname')
    const email = localStorage.getItem('user')
    const phone = localStorage.getItem('phone')
    const currency = country === "IN" ? "INR" : country === "US" ? "USD" : null;
    const navigate = useNavigate();
    const currencySymbols = { "INR": "₹", "USD": "$" };
    const [razorpayId, setRazorpayId] = useState('');

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            setIsAuthenticated(true);
        }
        setAuthChecked(true);
    }, []);

    useEffect(() => {
        const fetchCountryData = async () => {
            try {
                const response = await fetch(`/get/stage?id=${id}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `${token}`,
                    },
                });
                const data = await response.json();
                console.log(data);
                if (data.console){
                    if (data.console === 'UP') {
                        setCountry('US');
                    } else if (data.console === 'IP') {
                        setCountry('IN');
                    }
                }
                setRazorpayId(data.razorpay)
            } catch (error) {
                console.error('Error fetching country data:', error);
            }
        };

        fetchCountryData();
    }, [id, country]);


    useEffect(() => {
        const fetchOrderDetails = async () => {
            try {
                if (token){
                    setIsAuthenticated(true);
                    const response = await fetch(`/placeorder?id=${id}`, {
                        method: 'GET',
                        headers: {
                            'Authorization': `${token}`,
                        },
                    });
                    const data = await response.json();
                    setOrderDetails(data);
                } else{
                    console.log("saaad", countryData)
                    const updatedData = {
                       "name":countryData.name,
                       "commitment":countryData.subscriptionLength,
                       "Estimate_tax":countryData.tax_amount,
                       "billing":countryData.billingTerm,
                       "customer_name":countryData.customerName,
                       "address":{
                            "add1":countryData.addressLine1,
                            "add2":countryData.addressLine2, 
                            "pincode":countryData.pincode, 
                            "city":countryData.city, 
                            "state":countryData.state
                        },
                       "email":countryData.email,
                       "grand_total":countryData.totalCost,
                       "phone":countryData.phoneNumber,
                       "quantity":countryData.count,
                       "total_price":countryData.total,
                       "unit_price":countryData.unit_price,
                    }
                    setOrderDetails(updatedData);
                }
                setLoading(false);
            } catch (error) {
                setLoading(false);
                console.error('Error fetching order details:', error);
            }
        };

        fetchOrderDetails();
    }, [id]);

    const handlePaymentFailure = (details, message, url) => {
        console.error(message);
        setCaptureDetails(details);
        setRetryUrl(url);
        setShowRetryButton(true);
        setLoading(false)
      };

    const handlePlaceOrderClick = () => {
        if (isChecked) {
            let payment_data = {
                "id":id, "email":email, "first_name":name,"phone":phone, "totalAmount":orderDetails.grand_total, "tax_price":orderDetails.tax_amount, "isAuthenticated": isAuthenticated
            }
            if (isAuthenticated){payment_data["data"] = {}}else{payment_data["data"] = countryData}
            // setLoading(true)
            handlePayment(handlePaymentSuccess, handlePaymentFailure, "signUp", payment_data, setLoading, razorpayId);
        }
    };

    const handleCloseModal = (e) => {
        if (e.target.classList.contains('modal-overlay')) {
            setShowModal(false); // Close the modal if clicking outside the cardtax_amount
        }
    };

      const handleRetryClick = async () => {
        if (captureDetails) {
          setLoading(true);
          try {
            const result = await axios.post(retryUrl, captureDetails);
            if (result.data.status) {
              onAction();
              setLoading(false);
            } else {
              console.error('Retry failed: Backend validation failed.');
              setMessage(`Retry failed: Backend validation failed.`);
              setLoading(false);
            }
          } catch (error) {
            console.error('Retry failed:', error);
            setMessage(`Retry failed: ${error}`);
            setLoading(false);
          }
        }
      };

    const handleClosePopup = () => {
        setShowPopup(false);
    };
    const handleNotificationClose = () => {
        setMessage('');
    };
    const handleRadioChange = () => {
        setIsChecked(!isChecked);
    };
    const handlePaymentSuccess = (response) => {
        if (isAuthenticated){
            onAction();
        } else{
            debugger
            console.log("saad", response.id)
            const data = response.data
            fetch("/api/login", {
                method: "POST",
                headers: {
                  "Content-Type": "application/json"
                },
                body: JSON.stringify({ encodedEmail: data.email, encodedPassword: data.password  })
              })
                .then(response => response.json())
                .then(data => {
                setTimeout(() => {  // Delay before checking the token
                  if (data.token) {
                    // Store JWT token in localStorage
                    console.log(data  )
                    localStorage.setItem('userId', data.user.id)
                    localStorage.setItem("token", data.token);
                    localStorage.setItem("user", data.user.email)
                    localStorage.setItem("firstname", data.user.first_name)
                    localStorage.setItem("lastname", data.user.last_name)
                    localStorage.setItem("customerId", data.user.customerId)
                    localStorage.setItem("phone", data.user.phone)
                    navigate(`/order/${response.id}`, { state: { country: country } });
                    window.location.reload();
                  } else {
                    alert(data.error || "Login failed");
                  }
            }, 2000)
                })
                .catch(error => {
                  console.error("Error during login:", error);
                  alert("Login failed. Please try again.");
                })
        }
    };

    if (!orderDetails) {
        return (
            <div className="modal-overlay">
                <div className="preloader-modal">
                    <div className="spinner"></div>
                </div>
            </div>
        );
    }

    return (
        <div className="S3main-content">
            {loading && (
            <div className="modal-overlay">
                <div className="preloader-modal">
                    <div className="spinner"></div>
                </div>
            </div>
            )}
            <div className="S3frame-12">
                <div className="PFServicesHeading">
                    <div className='ItemDetails-heading'>
                        <span>Review and Place Order</span>
                        {/* <button className="view-plan-button" onClick={handleItemPopup}>
                            <img src={msicon2} alt="service-icon" className="ms-icon" />
                            View Service Details
                        </button> */}
                    </div>
                    <p className="S3check-fields">It is wise to check every field before placing the order.</p>
                </div>
                <div className="S3Fields">
                    <div className="S3Frame">
                        <div className='S3Frame1'>
                            <div className='S3Framecolumn'>
                                <span className="S3Label">Product Name:</span>
                                <span className="S3Value"> {orderDetails.name}</span>
                            </div>
                            <div className='S3Framecolumn'>
                                <span className="S3Label">Billing Commitment:</span>
                                <span className="S3Value"> {orderDetails.commitment}</span>
                            </div>
                        </div>
                        <div className='S3Frame1'>
                            <div className="S3Framecolumn">
                                <span className="S3Label">Price:</span>
                                <span className="S3Value"> {currencySymbols[currency]}{orderDetails.unit_price}</span>
                            </div>
                            <div className="S3Framecolumn">
                                <span className="S3Label">Quantity:</span>
                                <span className="S3Value"> X {orderDetails.quantity}</span>
                            </div>
                        </div>
                        <div className='S3Frame1'>
                            <div className="S3Framecolumn">
                                <span className="S3Label">Billing Plan:</span>
                                <span className="S3Value"> {orderDetails.billing}</span>
                            </div>
                            <div className="S3Framecolumn">
                                <span className="S3Label">Subtotal:</span>
                                <span className="S3Value"> {currencySymbols[currency]}{orderDetails.total_price} (tax not included)</span>
                            </div>
                        </div>
                    </div>

                    <div className="S3Frame S3FrameMiddle">
                        <div className="S3Frame1">
                            <div className="S3Framecolumn">
                                <span className="S3Label">Sold to Address: </span>
                                <span className="S3Value PFcontent">
                                    {orderDetails.address.add1}, <br /> {orderDetails.address.add2}<br />
                                    {orderDetails.address.city}, {orderDetails.address.state} - {orderDetails.address.pincode}, {orderDetails.address.country}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className='S3Frame'>
                        <div className="S3Frame1">
                            <div className="S3Framecolumn">
                            <span className="S3Value PFcontent">
                            <p>When your subscription starts, we'll charge you <b>{currencySymbols[currency]}{orderDetails.total_price}/{orderDetails.commitment}</b> plus applicable taxes every month. Charges will be included 
                            on the first invoice after the subscription starts. You'll be notified before any price changes. Cancel within 7 days of purchase for a prorated refund by visiting your Brio Storefront account. Refunds aren't available after 7 days.
                            <a href="https://learn.microsoft.com/en-us/microsoft-365/commerce/subscriptions/cancel-your-subscription?view=o365-worldwide"target="_blank" rel="noopener noreferrer">Learn more about cancelling</a>.</p><br />
                            <p>Read our <a href="https://privacy.microsoft.com/en-us/privacystatement"target="_blank" rel="noopener noreferrer">privacy statement</a> for information on how your data is handled. By selecting accept agreement & place order, you agree to the terms and conditions in the <a href="https://www.microsoft.com/licensing/docs/customeragreement"target="_blank" rel="noopener noreferrer">Microsoft Customer Agreement</a>.</p><br />
                            <p>New Global, Billing, and Reader administrators will be granted permission to the Brio Billing account. You can disable the permissions after sign-up.</p>
                            </span>
                            </div>
                        </div>
                    </div>

                    <div className="S3Frame">
                        <div className="S3Frame1">
                            <div className="S3Framecolumn">
                                <span className="S3Label">{orderDetails.commitment} commitment, billed {orderDetails.billing} (before tax):</span>
                            </div>
                            <div className="S3FrameAmount">
                                <span>{currencySymbols[currency]}{orderDetails.total_price}</span>
                            </div>
                        </div>
                        {currency === "INR" && ( // Only show if currency is not USD
                            <div className="S3Frame1">
                                <div className="S3Framecolumn">
                                    <span className="S3Label">Estimate taxes:</span>
                                </div>
                                <div className="S3FrameAmount">
                                    <span>{currencySymbols[currency]}{orderDetails.Estimate_tax}</span>
                                </div>
                            </div>
                        )}
                        <div className="S3Frame1">
                            <div className="S3Framecolumn">
                                <span className="S3Label">Total ({orderDetails.billing}):</span>
                            </div>
                            <div className="S3FrameAmount">
                                <span>{currencySymbols[currency]}{orderDetails.grand_total}</span>
                            </div>
                        </div>
                    </div>

                    <div className="S3Footer">
                    {showRetryButton ? (<>
                        <div className="S3FooterContent">
                            <span>Your payment has been completed. Please click 'Submit' to complete your order.</span>
                            <div onClick={handleRetryClick}>
                                <button className="S3PlaceOrderButton">Submit</button>
                            </div>
                        </div>
                    </>):(
                        <div className="S3FooterContent">
                            <span><input
                            type="checkbox"
                            id="termsConditions"
                            checked={isChecked}
                            onChange={handleRadioChange}
                        /> By placing order you are accepting to our terms and conditions</span>
                            <div onClick={handlePlaceOrderClick}>
                                <button className={`S3PlaceOrderButton ${!isChecked ? 'disabled' : ''}`}>Place Order</button>
                            </div>
                        </div>
                    )}
                    </div>
                </div>
            </div>
            {message && (
                <div className="error-msg-notification-container">
                    <Notification key={Date.now()} message={message} onClose={handleNotificationClose} />
                </div>
            )}
            {showPopup && (
                <div className="S3popup-overlay">
                    <S3PaymentModal close={handleClosePopup} />
                </div>
            )}
            {showModal && (
                <div className="modal-overlay" onClick={handleCloseModal}>
                    {service && <Card service={service} type='preview' />}
                </div>
            )}
        </div>
    );
};

export default ReviewPlaceOrder;
