import React, { useState } from "react";
import '../Subscription.css'; // Assuming this contains global styles

const GSTModal = ({ showModal, onClose, onSubmit }) => {
    const [hasGstNumber, setHasGstNumber] = useState(true);
    const [gstNumber, setGstNumber] = useState("");

    const handleSubmit = () => {
        if (hasGstNumber) {
            onSubmit(gstNumber);
        }
        onClose();
    };

    if (!showModal) {
        return null;
    }

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <div className="radio-group">
                    <label className="GSTradio-lable">
                        <input 
                            type="radio" 
                            name="gstOption" 
                            className="radioB "
                            value="hasGst" 
                            checked={hasGstNumber} 
                            onChange={() => setHasGstNumber(true)}
                        />
                        I have a GST number
                    </label>
                    {hasGstNumber && (
                        <div className="input-wrapper">
                            <input
                                type="text" 
                                name="GST-input"
                                className="GSTinput-field" 
                                placeholder="Enter your GST number to auto-fill the form." 
                                value={gstNumber}
                                onChange={(e) => setGstNumber(e.target.value)}
                            />
                        </div>
                    )}
                    <label className="GSTradio-lable">
                        <input 
                            type="radio" 
                            name="gstOption" 
                            className="radioB"
                            value="noGst" 
                            checked={!hasGstNumber} 
                            onChange={() => setHasGstNumber(false)}
                        />
                        I don't have a GST number, I will add details manually
                    </label>
                </div>
                <div className="modal-buttons">
                    <button 
                        className="button button-proceed" 
                        onClick={handleSubmit}
                        disabled={hasGstNumber && !gstNumber}
                    >
                        Proceed
                    </button>
                </div>
            </div>
        </div>
    );
};

export default GSTModal;
