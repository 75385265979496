import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './dashboard.css';
import india from '../Assets/india.svg';
import usa from '../Assets/usa.svg';
import logo from '../Assets/logo.svg';
import righticon from '../Assets/righticon1.svg';
import loginicon from '../Assets/loginicon.svg';
import carticon from '../Assets/carticon.svg';
import ordersicon from '../Assets/orders.svg';
import { Nav } from 'react-bootstrap';
import Notification from '../../components/Notification';
import BrioUser from "../Assets/BrioUser.png"
import order from '../Assets/order.svg';
import Subscription from '../PurchaceFlow/Subscription';

const Navbar = ({ sidebarOpen, toggleSidebar }) => {
    const [selectedCountry, setSelectedCountry] = useState({ name: 'India', icon: india });
    const [isOpen, setIsOpen] = useState(false);
    const [labelsOpen ,setLabelsOpen] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [showCountry, setShowCountry] = useState(true)
    const [showCountryName, setShowCountryName] = useState(true)
    const [notifications, setNotifications] = useState([]); // For managing notifications
    const [loading, setLoading] = useState(false);
    const [userName, setUserName] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const showSidebarBasePaths = ['/buy/more','/order', '/contract'];

    const location = useLocation();
    const navigate = useNavigate();

    const countries = [
        { name: 'India', icon: india },
        // { name: 'USA', icon: usa },
    ];
    const userLabels = [
        { name: "Subscription", route: '/subscriptions', icon: ordersicon},
        { name: 'Cart', route: '/cart', icon: carticon },
        { name: 'My Orders', route: '/Myorders', icon: order },
    ];
    const shouldShowSidebarIcon = showSidebarBasePaths.some(path => location.pathname.startsWith(path));
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        if (location.state?.searchQuery) {
            setSearchQuery(location.state.searchQuery);
        }
        const tokenList = params.getAll('jwt-token');
        if (tokenList.length > 0) {
            const token = tokenList[0];
            localStorage.setItem('token', token);
        }
    }, [location]);
    useEffect(() => {
        const updateServicesPerPage = () => {
            if (window.matchMedia('(max-width: 768px)').matches) {
                setShowCountry(false); // For screens smaller than 400px
            } else {
                setShowCountry(true); // Default for larger screens
            }
            if (window.matchMedia('(max-width: 915px)').matches) {
                setShowCountryName(false); // For screens smaller than 400px
            } else {
                setShowCountryName(true); // Default for larger screens
            }
            
        };

        updateServicesPerPage(); // Set initially
        window.addEventListener('resize', updateServicesPerPage); // Listen for resize events

        return () => window.removeEventListener('resize', updateServicesPerPage); // Clean up listener
    }, []);

    useEffect(() => {
        const checkAuthStatus = () => {
            const token = localStorage.getItem('token');
            const storedUserName = localStorage.getItem('firstname');
            
            if (token) {
                setIsAuthenticated(true);
                if (storedUserName) {
                    setUserName(storedUserName);
                }
            } else {
                setIsAuthenticated(false);
            }
        };
        checkAuthStatus();
        const interval = setInterval(() => {
            if (!isAuthenticated) {
                checkAuthStatus();
            }
        }, 5000);
        return () => clearInterval(interval);
    }, [isAuthenticated]);


    const selectCountry = (country) => {
        setSelectedCountry(country);
        setIsOpen(false);
        navigate(location.pathname, { state: { selectedCountry: country } });
    };

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };
    const toggleLabelsDropdown = () => {
        setLabelsOpen(!labelsOpen);
    };

    const handleLogout = () => {
        // localStorage.removeItem('token');
        localStorage.clear()
        setIsAuthenticated(false);
        navigate('/login');
    };

    const handleSearch = async () => {
        setLoading(true);
        try {
            const response = await fetch('/api/search', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ query: searchQuery }),
            });
    
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.body || 'Search failed');
            }
    
            const data = await response.json();
    
            // If the user is already on the /search page, update the state to force a re-render
            if (location.pathname === '/search') {
                console.log(location.pathname)
                navigate('/search', {
                    state: { services: data, selectedCountry, searchQuery, key: Date.now() },
                    replace: true,  // This ensures the navigation happens on the same route
                });
                window.location.reload();
            } else {
                navigate('/search', {
                    state: { services: data, selectedCountry, searchQuery },
                });
                setLoading(false); // Stop the loading state after successful search
            }
    
        } catch (error) {
            console.log(error);
            setNotifications((prev) => [
                ...prev,
                { id: Date.now(), message: error.message },
            ]);
            setLoading(false);
        }
    };
    
    const removeNotification = (id) => {
        setNotifications((prev) => prev.filter((notif) => notif.id !== id));
    };

    return (
        <Nav className="LPnavbar LPHeader">
            {loading && (
            <div className="modal-overlay">
                <div className="preloader-modal">
                    <div className="spinner"></div>
                </div>
            </div>
        )}
           

            <div style={{ display: 'flex', alignItems: 'center' }}>
                {shouldShowSidebarIcon && (
                    <div className="hamburger-icon" onClick={toggleSidebar}>
                        <span className={`bar ${sidebarOpen ? 'open' : ''}`}></span>
                        <span className={`bar ${sidebarOpen ? 'open' : ''}`}></span>
                        <span className={`bar ${sidebarOpen ? 'open' : ''}`}></span>
                    </div>
                )}

                <Link to="/">
                    <img src={logo} alt="Logo"  className='brio-logo' style={{ position: 'fixed', top: 20 }} />
                </Link>
            </div>

            {(location.pathname === '/' || location.pathname === '/search' || location.pathname === '/Home' || location.pathname === '/home' )&& (
                <div className="LPSearchContainer">
                    <input
                        type="text"
                        value={searchQuery} // Bind input value to state
                        onChange={(e) => setSearchQuery(e.target.value)} // Update state on input change
                        placeholder="Search"
                        className="LPSearchInput"
                    />
                    <div className="LPIconSearch" onClick={handleSearch} style={{ cursor: "pointer" }}>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18.9998 19L14.6568 14.657M14.6568 14.657C15.3997 13.9141 15.989 13.0322 16.3911 12.0616C16.7931 11.0909 17 10.0506 17 9.00002C17 7.94942 16.7931 6.90911 16.3911 5.93848C15.989 4.96785 15.3997 4.08591 14.6568 3.34302C13.914 2.60014 13.032 2.01084 12.0614 1.6088C11.0908 1.20675 10.0504 0.999817 8.99984 0.999817C7.94924 0.999817 6.90892 1.20675 5.93829 1.6088C4.96766 2.01084 4.08573 2.60014 3.34284 3.34302C1.84251 4.84335 0.999634 6.87824 0.999634 9.00002C0.999634 11.1218 1.84251 13.1567 3.34284 14.657C4.84317 16.1574 6.87805 17.0002 8.99984 17.0002C11.1216 17.0002 13.1565 16.1574 14.6568 14.657Z" stroke="#5B5B5B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>

                    </div>
                </div>
            )}

            <div className="LPHeaderButtons" >
                {showCountry && (location.pathname === '/' || location.pathname === '/search' || location.pathname === '/Home' || location.pathname === '/home') && (
                    <div className="LPcountry-selector" style={!isAuthenticated ? { right: '12%' } : {}}>
                        <div className="NBdropdown-header" onClick={toggleDropdown}>
                            <div className="LPcountry-icon" style={{ backgroundImage: `url(${selectedCountry.icon})` }} />
                            {showCountryName &&(
                            <span className="LPcountry-name">{selectedCountry.name}</span>
                            )}
                            <div className={`LParrow-icon ${isOpen ? 'open' : ''}`} />
                        </div>
                        {isOpen && (
                            <div className="LPdropdown-body">
                                {countries.map((country) => (
                                    <div
                                        key={country.name}
                                        className="LPdropdown-item"
                                        onClick={() => selectCountry(country)}
                                    >
                                        <div
                                            className="LPcountry-icon"
                                            style={{ backgroundImage: `url(${country.icon})` }}
                                        />
                                        <span className="LPcountry-name">{country.name}</span>
                                        {selectedCountry.name === country.name ? (
                                            <img src={righticon} alt="right" />
                                        ) : (
                                            <div className="LPcheck-icon1" />
                                        )}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                )}
                {isAuthenticated ? (
                    <>
                    <div className="LPLabel-selector">
                        <div className="NBLabel-header" onClick={toggleLabelsDropdown}>
                            <div className="LPlabel-icon" style={{ backgroundImage: `url(${BrioUser})` }} />
                            {(labelsOpen || showCountry) && (
                                <span className="LPcountry-name">{userName || 'User'}</span>
                            )}
                            <div className={`LParrow-icon ${labelsOpen ? 'open' : ''}`} />
                        </div>
                        {labelsOpen && (
                            <div className="LPdropdown-body">
                                {userLabels.map((label, index) => (
                                    <Link
                                        to={label.route}
                                        style={{
                                            textDecoration: 'none',
                                            backgroundColor: location.pathname === label.route ? '#f0f0f0' : 'transparent', // Highlight active route
                                            fontWeight: location.pathname === label.route ? 'bold' : 'normal', // Bold for active route
                                            padding: '10px'
                                        }}
                                        key={label.name}
                                        className="LPdropdown-item"
                                    >
                                        <div className="LPlabel-icon" style={{ backgroundImage: `url(${label.icon})` }} />
                                        <span className="LPcountry-name">{label.name}</span>
                                    </Link>
                                ))}
                            </div>
                        )}
                    </div>
                    <button onClick={handleLogout} className="NBPrimaryButton" style={{ textDecoration: 'none' }}>
                        <div className="LPicon-container">
                            <img src={loginicon} alt="Logout Icon" className="icon" />
                        </div>
                        {showCountry && (<span className="LPbutton-text">Log Out</span>)}
                    </button>
                    </>
                   
                ) : (
                
                    
                    <Link to="/login" className="NBPrimaryButton" style={{ textDecoration: 'none' }}>
                            <div className="LPicon-container">
                                <img src={loginicon} alt="Login Icon" className="icon" />
                            </div>
                            {showCountry && (<span className="LPbutton-text">Log In</span>)}
                    </Link>
                
                  
                )}
            </div>
            <div className="notification-container">
                {notifications.map((notification) => (
                    <Notification
                        key={notification.id}
                        message={notification.message}
                        onClose={() => removeNotification(notification.id)}
                    />
                ))}
            </div>
        </Nav>
    );
};

export default Navbar;