import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Logo from '../Assets/logo.svg'; // Replace with your logo SVG
import SupportIcon from '../Assets/support-icon.svg'; // Replace with your support icon SVG
import "./ResetPwd.css";
import axios from 'axios';

function ResetPassword() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    // Make GET request to fetch email by ID
    axios.get(`/reset-password/${id}`)
      .then((response) => {
        setEmail(response.data.email);
      })
      .catch((error) => {
        console.error('Error fetching email:', error);
      });
  }, [id]);


  const handleSubmit = () => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!email) {
      alert("Please enter your email.");
    } else if (!emailPattern.test(email)) {
      alert("Please enter a valid email address.");
    } else if (!newPassword) {
      alert("Please enter a new password.");
    } else if (newPassword.length < 8) {
      alert("Password must be at least 8 characters long.");
    } else if (newPassword !== confirmPassword) {
      setErrorMessage("Password do not match!");
      setSuccessMessage('');
    } else {
      // Perform the POST request to reset password
      axios.post(`/reset-password/${id}`, { password: newPassword })
        .then(response => {
          setSuccessMessage("Password Updated Successfully!");
          setErrorMessage('');
          setTimeout(() => {
            navigate('/login');
          }, 2000);
        })  
        .catch(error => {
          console.error('Error updating password:', error);
          setErrorMessage("Failed to update password.");
          setSuccessMessage('');
        });
    }
  };

  return (
    <div className="RSP-container">
       <div className="RSP-leftSection">
        <img src={Logo} alt="Brio Logo" className="RSP-logo" />
        <h1 className="RSP-slogan">
          Transforming workplaces into <br />
          Hubs of <span className="RSP-boldText">Happiness</span>
        </h1>
        <div className="RSP-support">
          <img src={SupportIcon} alt="Support Icon" className="RSP-supportIcon" />
          <a href="/support" className="RSP-supportText">Contact Support</a>
        </div>
      </div>
      <div className="RSP-rightSection">
        <h2 className="RSP-title">Reset Password</h2>
        {errorMessage && <div className="RSP-errorMessage">{errorMessage}</div>}
        <form className="RSP-form" onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
          <label className="RSP-label">Email</label>
          <input type="email" value={email} className="RSP-input" readOnly />
          <label className="RSP-label">New Password</label>
          <input
            type="password"
            placeholder="********"
            className="RSP-input"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <label className="RSP-label">Confirm New Password</label>
          <input
            type="password"
            placeholder="********"
            className="RSP-input"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <button type="submit" className="RSP-submitButton">Submit</button>
        </form>
        {successMessage && <div className="RSP-successMessage">{successMessage}</div>}
        <a href="/login" className="RSP-backToSignIn">Back to Sign In</a>
      </div>
    </div>
  );
}

export default ResetPassword;
