import axios from 'axios';

export const loadRazorpayScript = (src) => {
    return new Promise((resolve) => {
        const script = document.createElement('script');
        script.src = src;
        script.onload = () => {
            console.log('Razorpay SDK loaded successfully');
            resolve(true);
        };
        script.onerror = () => {
            console.log('Error loading Razorpay SDK');
            resolve(false);
        };
        document.body.appendChild(script);
    });
};

export const handlePayment = async (onPaymentSuccess, onPaymentFailure, flow_type, paymentData, setLoading, razorpayId ) => {
    console.log(paymentData, 'paymentData')
    const res = await loadRazorpayScript('https://checkout.razorpay.com/v1/checkout.js');

    if (!res) {
        alert('Failed to load Razorpay SDK.');
        return;
    }
    function generateUUID() {
        var d = new Date().getTime();
        var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
        return uuid;
    };
    var guid = generateUUID()

    const options = {
        key: razorpayId,
        amount: Math.round(paymentData.totalAmount) * 100,
        currency: "INR",
        name: "Brio Technologies Private Limited",
        handler: async function (response) {
            console.log('Payment Successful:', response);
            // alert(`Payment successful! Payment ID: ${response.razorpay_payment_id}`);
            let captureDetails = {};
            let url = '';
            if (flow_type === 'renewSubscription') {
                const payment_details = {
                    id: response.razorpay_payment_id,
                    amount: paymentData.RenewDueAmount,
                    total_amount: paymentData.totalAmount,
                    tax_rate:paymentData.taxRate,
                    tax_amount:paymentData.tax_price,
                    name: paymentData.name,
                    email: paymentData.email,
                    phone: paymentData.phone,
                    quantity: paymentData.count,
                    identifier: guid,
                    order_type: 'RENEW',
                    userId: paymentData.userId,
                };
                captureDetails = { payment_details,  subscription_id: paymentData.id};
                url = `/renew-subscription/${paymentData.id}`;
            } else if (flow_type === 'buyMore') {
                const payment_details = {
                    id: response.razorpay_payment_id,
                    amount: paymentData.totalAmountWithOutTax,
                    total_amount: paymentData.totalAmount,
                    tax_rate:paymentData.taxRate,
                    tax_amount:paymentData.taxAmount,
                    name: paymentData.name,
                    email: paymentData.email,
                    phone: paymentData.phone,
                    quantity: paymentData.quantity,
                    console: paymentData.console,
                    identifier: guid,
                    order_type: 'ADD',
                    userId: paymentData.userId,
                };
                captureDetails = { payment_details,  subscription_id: paymentData.id};
                url = `/add/user/${paymentData.id}`;
            } else if (flow_type === 'signUp') {
                captureDetails = {
                    payment_details:{
                        id: response.razorpay_payment_id,
                        amount: paymentData.totalAmount,
                        order_id: paymentData.id,
                        email: paymentData.first_name,
                        name: paymentData.email,
                        phone: paymentData.phone,
                        identifier: guid,
                    },
                    data:paymentData,
                };
                url = `/api/capturepayment`;
            }

            try {
                console.log(captureDetails, 'captureDetails');
                setLoading(true);
                const result = await axios.post(url, captureDetails);
                console.log(result.data.status, '000000');
                if (result.data.status) {
                    console.log(response, result)
                    onPaymentSuccess(result.data);
                } else {
                    onPaymentFailure(captureDetails, 'Payment processed but backend validation failed.',url);
                }
                console.log(result, 'result');
                console.log('Payment details captured:', result.data);
                // setLoading(false);
            } catch (error) {
                console.error('Error capturing payment details:', error);
                setLoading(false);
                onPaymentFailure(captureDetails, 'Payment processed but backend validation failed.',url);
            }

        },
        prefill: {
            name: paymentData.first_name,
            email: paymentData.email,
            contact: paymentData.phone,
        },
        notes: {
            address: "Your Address"
        },
        theme: {
            color: "#FFF200"
        },
        method: {
            card: true,
        }
    };

    try {
        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
    } catch (error) {
        console.error('Error opening Razorpay:', error);
    }
};