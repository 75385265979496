// import React, {useEffect,useState} from 'react';
// import '../MyOrders/MyOrders.css';
// import { Link } from 'react-router-dom';
// import Navbar from "../Dashboard/Navbar";
// import msicon from '../Assets/msicon.svg';
// import axios from 'axios';
// import buynow from "../Assets/buynow.svg";
// import detailsicon from "../Assets/detailsicon.svg";
// import backicon from "../Assets/backicon.svg";

// import addicon from "../Assets/addicon.svg";
// import refreshicon from "../Assets/refreshicon.svg";

// const Orders = () => {
//   const [isPopupOpen, setIsPopupOpen] = useState(false);
//   const [orderItems, setOrderItems] = useState([]);
//   const [selectedOrder , setSelectedOrder] = useState();
//   const [loading, setLoading] = useState(true);

//     useEffect(() => {
//         const category = "ORD";
//         axios.get(`/api/get/orders?category=${category}`)
//             .then(response => {
//                 const data = response.data;
//                 setOrderItems(data);
//                 setLoading(false);
//                 console.log(data.filter(item=>item.itemName===undefined), 'data');
//             })
//             .catch(error => {
//                 console.error("There was an error fetching the subscriptions!", error);
//             });
//     }, []);
//   const formatDate = (dateString) => {
//     const date = new Date(dateString);
//     const day = date.getDate();
//     const month = date.toLocaleString("default", { month: "long" });
//     const year = date.getFullYear();

//     const ordinalSuffix = (day) => {
//       if (day > 3 && day < 21) return "th";
//       switch (day % 10) {
//         case 1:
//           return "st";
//         case 2:
//           return "nd";
//         case 3:
//           return "rd";
//         default:
//           return "th";
//       }
//     };

//     const suffix = ordinalSuffix(day);
//     return (
//       <>
//         {day}
//         <sup>{suffix}</sup> {month} {year}
//       </>
//     );
//   };
//   const handleArrowClick = (order) => {
//     setIsPopupOpen(true);
//     setSelectedOrder(order);
//   };

//   const closeModal = () => {
//     setIsPopupOpen(false);
//   };

//   return (
//     <div className="landing-page">
//       <Navbar />
//       {loading ? (
//                 <div className="modal-overlay">
//                     <div className="preloader-modal">
//                         <div className="spinner"></div>
//                     </div>
//                 </div>
//             ) : (
  
//       <div className="order-subscriptions-container">
//         <div className="order-title-adjust">
//           <div className="order-title-box">
//             <h3 className="order-title">My Orders</h3>
//           </div>
//         </div>
//         <p className="sub-titl">Review Order Items</p>
//         <div className="order-subscriptions-box">
//           <div className="order-section">
//             <div className="order-header">
//               <div className="order-header-item-service">Service Details</div>
//               <div className="order-header-item1">Purchased Licenses</div>
//               <div className="order-header-item3">Expiring On</div>
//               <div className="order-header-item2">Next Billing Date</div>
//               <div className="order-header-item5">Order Type</div>
//               <div className="order-header-item5">Details</div>
//             </div>
//             <div className="order-data-container">
          
//             {orderItems && orderItems.map((item) => (
//               item.itemName ? ( // Check if itemName exists
//                 <div key={item.id} className="order-subscription-item">
//                   <div className="order-service-details">
//                     <img
//                       src={msicon}
//                       alt="service-icon"
//                       className="order-service-icon"
//                     />
//                     <div className="details-p">
//                       <p className="order-service-name">
//                         {item.itemName}
//                       </p>
//                       <p className="order-service-plan">
//                         {item.commitment_plan &&
//                           (item.commitment_plan === "P1M" || item.commitment_plan === "M"
//                             ? "Monthly Plan"
//                             : item.commitment_plan === "A"
//                             ? "Yearly Plan"
//                             : item.commitment_plan)}
//                         {item.billing_cycle &&
//                           (item.billing_cycle === "P1M" || item.billing_cycle === "M"
//                             ? "(Monthly billing)"
//                             : item.billing_cycle === "A"
//                             ? "(Yearly billing)"
//                             : item.billing_cycle)}
//                       </p>
//                     </div>
//                   </div>
//                   <div className="order-license-count">
//                     <span>x</span>
//                     {item.quantity}
//                   </div>
//                   <div className="order-expiring-on">
//                     {item.expiry_date && formatDate(item.expiry_date)}
//                   </div>
//                   <div className="order-billing-date">
//                     {item.created_on && formatDate(item.created_on)}
//                   </div>
//                   <div className="order-billing-date1">
//                     {item.order_type && item.order_type}
//                   </div>
//                   <div
//                     className="order-actions"
//                     onClick={() => handleArrowClick(item)}
//                     style={{ cursor: "pointer" }}
//                   >
//                    <img src={detailsicon} />
//                   </div>

//                   {isPopupOpen && (
//                     <div className="order-modal-overlay" onClick={closeModal}>
//                       <div
//                         className="myorders-modal-content"
//                         onClick={(e) => e.stopPropagation()}
//                       >
//                         <div className="order-popup">
//                           <div className="order-popup-header">
//                             <img src={backicon}  onClick={closeModal} style={{cursor:"pointer"}}/>
//                             <img
//                               src={msicon}
//                               alt="service-icon"
//                               className="order-service-icon1"
//                             />
//                             <span style={{ fontSize: "16px" }}>
//                               {selectedOrder.itemName}
//                             </span>
//                             <span>x {selectedOrder.quantity}</span>
//                           </div>
//                           <div className="order-popup-body">
//                             <div className="order-popup-section">
//                               <p className="p-details">Price Details:</p>
//                               <p>
//                                 Total Amount (1 item):{" "}
//                                 <span style={{ fontWeight: "bold" }}>₹{selectedOrder.total}</span>
//                               </p>
//                               <p>
//                                 Tax Amount:{" "}
//                                 <span style={{ fontWeight: "bold" }}>₹{selectedOrder.tax_amount}</span>
//                               </p>
//                             </div>
//                             <div className="order-popup-section">
//                               <p className="p-details">Sold to Address:</p>
//                               <p>
//                                 {selectedOrder.shipping_address["address_line_1"] +
//                                   "," +
//                                   selectedOrder.shipping_address["address_line_2"]}
//                                 <br />
//                                 {selectedOrder.shipping_address["city"] +
//                                   "," +
//                                   selectedOrder.shipping_address["state"] +
//                                   selectedOrder.shipping_address["country"]}
//                               </p>
//                             </div>
//                             <div className="order-popup-section">
//                               <p className="p-details">Commitment Plan</p>
//                               <p>
//                                 {selectedOrder.commitment_plan &&
//                                   (selectedOrder.commitment_plan === "P1M" ||
//                                   selectedOrder.commitment_plan === "M"
//                                     ? "Monthly"
//                                     : selectedOrder.commitment_plan === "A"
//                                     ? "Yearly"
//                                     : selectedOrder.commitment_plan)}
//                               </p>
//                             </div>
//                             <div className="order-popup-section">
//                               <p className="p-details">Billing Plan</p>
//                               <p>
//                                 {selectedOrder.billing_cycle &&
//                                   (selectedOrder.billing_cycle === "P1M" ||
//                                   selectedOrder.billing_cycle === "M"
//                                     ? "Monthly"
//                                     : selectedOrder.billing_cycle === "A"
//                                     ? "Yearly"
//                                     : selectedOrder.billing_cycle)}
//                               </p>
//                             </div>
//                           </div>
//                           <div className="order-popup-footer">
//                             <p>Grand Total: </p>
//                             <span>₹{selectedOrder.grand_total}</span>
//                           </div>
                          
//                         </div>
//                       </div>
//                     </div>
//                   )}
//                 </div>
//               ) : null // Skip the item if itemName is not present
//             ))}
//  {/* <-- Close the map function here */}
//             </div>
//           </div>
//         </div>
//       </div>
//             )}

//     </div>
//   );
  
// };

// export default Orders;










import React, {useEffect,useState} from 'react';
import '../MyOrders/MyOrders.css';
import { Link } from 'react-router-dom';
import Navbar from "../Dashboard/Navbar";
import msicon from '../Assets/msicon.svg';
import axios from 'axios';
import buynow from "../Assets/buynow.svg";
import detailsicon from "../Assets/detailsicon.svg";
import backicon from "../Assets/backicon.svg";

import addicon from "../Assets/addicon.svg";
import refreshicon from "../Assets/refreshicon.svg";

const Orders = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [orderItems, setOrderItems] = useState([]);
  const [selectedOrder , setSelectedOrder] = useState();
  const [dataFetched, setDataFetched] = useState(false);
  const [loading, setLoading] = useState(true);

    useEffect(() => {
        const category = "ORD";
        const user = localStorage.getItem('userId');
        axios.get(`/api/get/orders?category=${category}&user=${user}`)
            .then(response => {
                const data = response.data;
                setOrderItems(data);
                setDataFetched(true)
                setLoading(false);
                console.log(data.filter(item=>item.itemName!==undefined), 'data');
            })
            .catch(error => {
                console.error("There was an error fetching the subscriptions!", error);
            });
    }, []);
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();

    const ordinalSuffix = (day) => {
      if (day > 3 && day < 21) return "th";
      switch (day % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    };

    const suffix = ordinalSuffix(day);
    return (
      <>
        {day}
        <sup>{suffix}</sup> {month} {year}
      </>
    );
  };
  const handleArrowClick = (order) => {
    setIsPopupOpen(true);
    setSelectedOrder(order);
  };

  const closeModal = () => {
    setIsPopupOpen(false);
  };

  return (
    <div className="landing-page">
      <Navbar />
      {loading ? (
                <div className="modal-overlay">
                    <div className="preloader-modal">
                        <div className="spinner"></div>
                    </div>
                </div>
            ) : (
  
      <div className="order-subscriptions-container">
        <div className="order-title-adjust">
          <div className="order-title-box">
            <h3 className="order-title">My Orders</h3>
          </div>
        </div>
        <p className="sub-titl">Review Order Items</p>
       
<div className='order-subscriptions-box'>
<div className="order-table-wrapper">
  <table className="order-table">
    <thead>
      <tr style={{borderRadius:'8px'}}>
        <th>Service Details</th>
        <th>Purchased Licenses</th>
        <th>Created On</th>
        <th>Order Type</th>
        <th>Details</th>
      </tr>
    </thead>
    <tbody>
    {orderItems && orderItems.length > 0 ? (
      orderItems.map(item => {
          // item.itemName && item.subscription_details ? (
          return ( <tr key={item.id} className="order-subscription-item">
              <td className="order-service-details">
                <img
                  src={msicon}
                  alt="service-icon"
                  className="order-service-icon"
                />
                <div className="details-p">
                  <p className="order-service-name">{item.itemName}</p>
                  <p className="order-service-plan">
                    {item.commitment_plan &&
                      (item.commitment_plan === "P1M" || item.commitment_plan === "M"
                        ? "Monthly Plan"
                        : item.commitment_plan === "A" || item.commitment_plan === "P1Y"
                        ? "Yearly Plan"
                        : item.commitment_plan)}
                    {item.billing_cycle &&
                      (item.billing_cycle === "P1M" || item.billing_cycle === "M"
                        ? "(Monthly billing)"
                        : item.billing_cycle === "A" || item.billing_cycle==="P1Y"
                        ? "(Yearly billing)"
                        : item.billing_cycle)}
                  </p>
                </div>
              </td>
              <td className="order-license-count">
                <span style={{ marginRight: "4px" }}>x</span>
                {item.quantity}
              </td>
              <td className="order-expiring-on">
              {item.created_on && formatDate(item.created_on)}
              </td>
              <td className="order-billing-date1">
                {item.order_type && item.order_type}
              </td>
              <td className="order-actions" onClick={() => handleArrowClick(item)}>
                <img src={detailsicon} alt="details" style={{ cursor: "pointer" }} />
              </td>
            </tr>
            );
            })
        ) : (
            dataFetched ? (<tr>
              <td className="no-subscriptions" colSpan="5" style={{ textAlign: 'center', padding: '40px', boxSizing:'content-box', color:'#e25555', border: '1px solid #e0e0e0'}}>
                <h5>Orders not found.</h5>
              </td>
            </tr>) : null
        )}
    </tbody>
  </table>
</div>
</div>


  {isPopupOpen && (
                    <div className="order-modal-overlay" onClick={closeModal}>
                      <div
                        className="myorders-modal-content"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <div className="order-popup">
                          <div className="order-popup-header">
                            <img src={backicon}  onClick={closeModal} style={{cursor:"pointer"}} />
                            <img
                              src={msicon}
                              alt="service-icon"
                              className="order-service-icon1"
                            />
                            <span className='item-name-model' style={{ fontSize: "16px" }}>
                              {selectedOrder.itemName} - #{selectedOrder.id}
                            </span>
                            <span className='item-name-model' style={{minWidth:'14px'}} >x {selectedOrder.quantity}</span>
                          </div>
                          <div className="order-popup-body">
                            <div className="order-popup-section">
                              <p className="p-details">Price Details:</p>
                              <p>
                                Total Amount (1 item):{" "}
                                <span style={{ fontWeight: "bold" }}>₹{selectedOrder.total}</span>
                              </p>
                              <p>
                                Tax Amount:{" "}
                                <span style={{ fontWeight: "bold" }}>₹{selectedOrder.tax_amount}</span>
                              </p>
                            </div>
                            <div className="order-popup-section">
                              <p className="p-details">Sold to Address:</p>
                              <p>
                                {selectedOrder.shipping_address["add1"] }
                                  <br />
                                  {selectedOrder.shipping_address["add2"]}
                                <br />
                                {selectedOrder.shipping_address["city"] +
                                  "," +
                                  selectedOrder.shipping_address["state"] +
                                  "," +
                                  selectedOrder.shipping_address["country"]}
                              </p>
                            </div>
                            {/* <div className="order-popup-section">
                              <p className="p-details">Commitment Plan</p>
                              <p>
                                {selectedOrder.commitment_plan &&
                                  (selectedOrder.commitment_plan === "P1M" ||
                                  selectedOrder.commitment_plan === "M"
                                    ? "Monthly"
                                    : selectedOrder.commitment_plan === "A"
                                    ? "Yearly"
                                    : selectedOrder.commitment_plan)}
                              </p>
                            </div>
                            <div className="order-popup-section">
                              <p className="p-details">Billing Plan</p>
                              <p>
                                {selectedOrder.billing_cycle &&
                                  (selectedOrder.billing_cycle === "P1M" ||
                                  selectedOrder.billing_cycle === "M"
                                    ? "Monthly"
                                    : selectedOrder.billing_cycle === "A"
                                    ? "Yearly"
                                    : selectedOrder.billing_cycle)}
                              </p>
                            </div> */}
                          </div>
                          <div className="order-popup-footer">
                            <p>Grand Total: </p>
                            <span>₹{selectedOrder.grand_total}</span>
                          </div>
                          
                        </div>
                      </div>
                    </div>
                  )}


      </div>
            )}

    </div>
  );
  
};

export default Orders;
