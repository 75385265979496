import React, { useState, useEffect } from "react";
import GSTModal from './GSTModal';
import buynow from '../Assets/buynow.svg';
import phoneNumbericon from '../Assets/phoneNumbericon.svg';
import { useNavigate, useParams } from 'react-router-dom';
import './purchaceFlow.css';
import Dropdownicon from '../Assets/Dropdownicon1.svg';
import Notification from "../../components/Notification";
import msicon2 from '../Assets/msicon2.svg';
import Card from "../../components/itemCard";

const CreateAccount = ({ onAction, countryData }) => {
    const navigate = useNavigate();
    const { id } = useParams();
    const { country } = countryData;
    const [showGSTModal, setShowGSTModal] = useState(false);
    const [gstHeader, setGstHeader] = useState('');
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const token = localStorage.getItem('token');
    const [notifications, setNotifications] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [service, setService] = useState(null);
    const [password, setPassword] = useState('');
    const [isUsedEmail, setIsUsedEmail] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [message, setMessage] = useState('');
    const [formData, setFormData] = useState({
        companyName: '',
        email: '',
        firstName: '',
        lastName: '',
        phoneNumber: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        pincode: '',
        country: country || '', // Use country if available, otherwise empty string
        organisationRegistrationNumber: '',
        gst_code:'',
    });
    const [email, setEmail] = useState(formData.email);
    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            setIsAuthenticated(true);
        } else {
            setIsAuthenticated(false);
        }
    }, []);
    useEffect(() => {
        setLoading(true)
        if (!country) {
            fetchCountryData();
        } else if (country === "IN") {
            setShowGSTModal(true);
        }
        setLoading(false)
    }, [country]);

    const fetchCountryData = async () => {
        try {
            setLoading(true)
            const response = await fetch(`/get/stage?id=${id}`, {
                method: 'GET',
                headers: {
                    'Authorization': `${token}`,
                },
            } );
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            const { console } = data;

            let countryCode = '';
            if (console === "UP") {
                countryCode = "US";
            } else if (console === "IP") {
                countryCode = "IN";
            }

            setFormData(prevFormData => ({
                ...prevFormData,
                country: countryCode
            }));

            // Set GST modal visibility based on country code
            if (countryCode === "IN") {
                setShowGSTModal(true);
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.error("Error fetching country data:", error);
        }
    };

    const handleCloseModal = (e) => {
        if (e.target.classList.contains('modal-overlay')) {
            setIsUsedEmail(false); // Close the modal if clicking outside the card
        }
    };

    const handleNotificationClose = () => {
        setMessage('');
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (name === 'phoneNumber') {
            if (!/^\d*$/.test(value)) return;
        }

        setFormData({
            ...formData,
            [name]: value
        });

        setErrors({
            ...errors,
            [name]: ''
        });
    };

    const validateForm = () => {
        let newErrors = {};
        let isValid = true;

        for (let key in formData) {
            if (formData[key] === '' && key !== 'gst_code') {
                newErrors[key] = `${key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1')} field is required.`;
                isValid = false;
            }
            const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            const phonePattern = /^\d{10}$/;

            if (key === "email" && !emailPattern.test(formData[key])) {
                newErrors[key] = "Please enter valid email"
                isValid = false;
            }
            if (key === "phoneNumber" && !phonePattern.test(formData[key])) {
                newErrors[key] = "Please enter valid phone number"
                isValid = false;
            }
        }

        setErrors(newErrors);
        return isValid;
    };

    const handleGSTSubmit = async (gstNumber) => {
        setLoading(true);
        try {
            const response = await fetch('/api/gst', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${token}`,
                },
                body: JSON.stringify({ gstin: gstNumber })
            });

            if (!response.ok) {
                // Extract error message from response
                const errorData = await response.json();
                console.log(errorData)
                throw new Error(errorData.body || `HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            const custData = data.cust_data;

            setFormData({
                companyName: custData.tradeName || "",
                email: "",
                firstName: "",
                lastName: "",
                phoneNumber: "",
                addressLine1: `${custData.buildingName || ""}, ${custData.floorNumber || ""}, ${custData.street || ""}`.trim(),
                addressLine2: custData.buildingNumber || "",
                city: custData.city || "",
                state: custData.stateName || "",
                pincode: custData.pincode || "",
                country: formData.country, // Keep the fetched country
                organisationRegistrationNumber: custData.gstin || "",
                gst_code: custData.gstin || "",
            });    
            setGstHeader(custData.gstin || ""); // Update GST header state
            setShowGSTModal(false);  // Close the modal after successful submission
            setLoading(false);
        } catch (error) {
            console.error("Error fetching GST data:", error);
            setNotifications((prev) => [
                ...prev,
                { id: Date.now(), message: error.message },
            ]);
            setLoading(false);
        }
    };

    const handleGSTpopup = () => {
        debugger
        if (formData.country.toLowerCase() === "in" || formData.country.toLowerCase() === "india") {
            setShowGSTModal(true);
        }
    };

    const handleNextPage = async () => {
        if (validateForm()) {
            try {
                const updatedFormData = { ...formData, id };
                setLoading(true);
                if (isAuthenticated){
                    const response = await fetch('/createacount', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `${token}`,
                        },
                        body: JSON.stringify(updatedFormData)
                    });
                    
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
                    
                    const result = await response.json();
                    console.log("Account created successfully:", result);
                    onAction();
                }else{
                    const response = await fetch(`/check/user?email=${formData.email}`);
                    const data = await response.json();
                    console.log(countryData)
                    console.log(formData)
                    setIsUsedEmail(data.user)
                    if(data.user == false){
                        onAction(updatedFormData)
                    }
                }
                window.scrollTo(0, 0);
            } catch (error) {
                setMessage(`Error creating account: ${error}`);
                setLoading(false);
            }
        }
    };
    const handleLogin = async (e) => {
        e.preventDefault();  // Prevent form submission
        setIsUsedEmail(false);
        setLoading(true);
    
        try {
            const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

            if (!email || !password) {
            alert("Username or password must not be empty");
            return;
            }

            if (!emailPattern.test(email)) {
            alert("Username must be a valid Gmail address (e.g., user@gmail.com)");
            return;
            }

            var Base64 = {
                // private property
                _keyStr: "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=",

                // public method for encoding
                encode: function (input) {
                    var output = "";
                    var chr1, chr2, chr3, enc1, enc2, enc3, enc4;
                    var i = 0;

                    input = Base64._utf8_encode(input);

                    while (i < input.length) {

                        chr1 = input.charCodeAt(i++);
                        chr2 = input.charCodeAt(i++);
                        chr3 = input.charCodeAt(i++);

                        enc1 = chr1 >> 2;
                        enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
                        enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
                        enc4 = chr3 & 63;

                        if (isNaN(chr2)) {
                            enc3 = enc4 = 64;
                        } else if (isNaN(chr3)) {
                            enc4 = 64;
                        }

                        output = output +
                            Base64._keyStr.charAt(enc1) + Base64._keyStr.charAt(enc2) +
                            Base64._keyStr.charAt(enc3) + Base64._keyStr.charAt(enc4);
                    }

                    return output;
                },

                // private method for UTF-8 encoding
                _utf8_encode: function (string) {
                    string = string.replace(/\r\n/g, "\n");
                    var utftext = "";

                    for (var n = 0; n < string.length; n++) {

                        var c = string.charCodeAt(n);

                        if (c < 128) {
                            utftext += String.fromCharCode(c);
                        }
                        else if ((c > 127) && (c < 2048)) {
                            utftext += String.fromCharCode((c >> 6) | 192);
                            utftext += String.fromCharCode((c & 63) | 128);
                        }
                        else {
                            utftext += String.fromCharCode((c >> 12) | 224);
                            utftext += String.fromCharCode(((c >> 6) & 63) | 128);
                            utftext += String.fromCharCode((c & 63) | 128);
                        }
                    }
                    return utftext;
                },
            }
            var encodedEmail = Base64.encode(email);
            var encodedPassword = Base64.encode(password)
            // First API call: Login
            const loginResponse = await fetch("/api/login", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ encodedEmail: encodedEmail, encodedPassword: encodedPassword }),
            });
    
            const loginData = await loginResponse.json();
    
            if (loginData.token) {
                formData['userid']=loginData.user.id
                // Store JWT token and user details in localStorage
                localStorage.setItem("userId", loginData.user.id);
                localStorage.setItem("token", loginData.token);
                localStorage.setItem("user", loginData.user.email);
                localStorage.setItem("firstname", loginData.user.first_name);
                localStorage.setItem("lastname", loginData.user.last_name);
                localStorage.setItem("customerId", loginData.user.customerId);
                localStorage.setItem("phone", loginData.user.phone);
    
                // Second API call: Create order, only if login is successful
                const updatedFormData = { ...formData, ...countryData };
                const orderResponse = await fetch("/create/order", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `${loginData.token}`,
                    },
                    body: JSON.stringify(updatedFormData),
                });
    
                const orderData = await orderResponse.json();
                console.log("Order created successfully:", orderData);
    
                // Navigate to order page with the created order ID
                navigate(`/order/${orderData.id}`);
            } else {
                // If login fails, show error and stop further actions
                alert(loginData.error || "Login failed");
                setLoading(false);
            }
        } catch (error) {
            console.error("Error during login:", error);
            alert("Login failed. Please try again.");
            setLoading(false);
        }
    };    

    const removeNotification = (id) => {
        setNotifications((prev) => prev.filter((notif) => notif.id !== id));
    };

    return (
        <div className="main-content">
            {showGSTModal && (
                <GSTModal 
                    showModal={showGSTModal} 
                    onClose={() => setShowGSTModal(false)} 
                    onSubmit={handleGSTSubmit} 
                />
            )}
            {loading && (
                <div className="modal-overlay">
                    <div className="preloader-modal">
                        <div className="spinner"></div>
                    </div>
                </div>
            )}
            {isUsedEmail&&(
            <div className="modal-overlay" onClick={handleCloseModal}>
                <div className="modal-content">
                    <h5>Account already exists, please Login instead</h5>
                    <p className="login-heading">The email you entered already exists in our system. If you'd like to continue with this email, please log in to your account. Alternatively, you can enter a different email to proceed. To login, fill the credentials below.</p>
                    <div className="login-form" >
                    <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input
                        type="email"
                        id="email"
                        name="email"
                        placeholder="Enter your email id here"
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        className="input-field"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="password">Password</label>
                        <input
                        type="password"
                        id="password"
                        name="password"
                        placeholder="Enter your password here"
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        className="input-field"
                        />
                    </div>
                    </div>
                    <div className="login">
                        <button onClick={handleLogin} className="login-btn">
                            Login
                        </button>
                    </div>
                </div>
            </div>
            )}
            <div className="PFServicesHeading">
                <div className='ItemDetails-heading'>
                    <span>Create Account</span>
                    {/* <button className="view-plan-button" onClick={handleItemPopup}>
                        <img src={msicon2} alt="service-icon" className="ms-icon" />
                        View Item Details
                    </button> */}
                </div>
                <p>
                    Enter your work or school email address. We will check if you need to
                    create a new account with Brio.
                </p>
            </div>

            <div className="form-container">
            {(["in", "india"].includes(formData.country.toLowerCase())) && gstHeader && (
                <>
                    <h3 style={{ textAlign: 'center', marginBottom: '20px' }}>
                        GST: {gstHeader}
                    </h3>
                </>
            )}

                <div className="form-fields">
                    {['companyName', 'email', 'firstName', 'lastName', 'phoneNumber', 'addressLine1', 'addressLine2', 'city', 'state', 'pincode', 'country', 'organisationRegistrationNumber'].map(field => (
                        <div className="form-group" key={field}>
                            <label className="label">{field.charAt(0).toUpperCase() + field.slice(1).replace(/([A-Z])/g, ' $1')}</label>
                            {field === 'phoneNumber' ? (
                                <>
                                    <div className="phone-input">
                                        <div className="country-code">
                                            <select className="country-code-dropdown">
                                                <option value="+91">+91</option>
                                                <option value="+1">+1</option>
                                                <option value="+44">+44</option>
                                            </select>
                                            <img src={Dropdownicon} className="country-code-dropdown" alt="Dropdown Icon" />
                                        </div>
                                        
                                        <input 
                                            type="text" 
                                            className="input-field phone-number" 
                                            placeholder="00000 00000" 
                                            name="phoneNumber"
                                            value={formData.phoneNumber}
                                            onChange={handleInputChange}
                                            style={{borderRadius:'0px 4px 4px 0px'}}
                                        />
                                    </div>
                                </>
                            ) : (
                                <>
                                    <input 
                                        type={field === 'email' ? 'email' : 'text'}
                                        className="input-field" 
                                        placeholder={`Enter ${field.charAt(0).toUpperCase() + field.slice(1)}`} 
                                        name={field}
                                        value={formData[field]}
                                        onChange={handleInputChange}
                                        readOnly={field === 'country'}
                                    />
                                </>
                            )}
                           
                            {errors[field] && (
                                <div className="errorText">
                                    {errors[field]}
                                </div>
                            )}
                        </div>
                    ))}
                </div>

                <div className="CA-frame26cont">
                    <div className="GST-btn">
                    {(formData.country.toLowerCase() === "in" || formData.country.toLowerCase() === "india") && (
                        <p onClick={handleGSTpopup} className="GST-popup">Fill Details GST</p>
                    )}
                    </div>
                    <div className='CA-frame26' onClick={handleNextPage}>
                        <span>Next</span>
                        <img src={buynow} alt="Next"/>
                    </div>
                </div>
            </div>
            <div className="notification-container">
                {notifications.map((notification) => (
                    <Notification
                        key={notification.id}
                        message={notification.message}
                        onClose={() => removeNotification(notification.id)}
                    />
                ))}
            </div>
            {message && (
                <div className="error-msg-notification-container">
                    <Notification key={Date.now()} message={message} onClose={handleNotificationClose} />
                </div>
            )}
            {showModal && (
                <div className="modal-overlay" onClick={handleCloseModal}>
                    {service && <Card service={service} type='preview' />}
                </div>
            )}
        </div>
    );
};

export default CreateAccount;