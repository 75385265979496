import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Navbar from './Navbar';
import Card from './Card';
import Pagination from './Pagination';
import './dashboard.css';

const LandingPage = () => {
    const [servicesPerPage, setServicesPerPage] = useState(3);
    const [services, setServices] = useState([]);
    const [otherServices, setOtherServices] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentOtherPage, setCurrentOtherPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const location = useLocation();
    const selectedCountry = location.state?.selectedCountry || {name: 'India',icon: '',};
    const  country_code = selectedCountry.name === "India" ? "IN" : selectedCountry.name === "USA" ? "US" : null;

    useEffect(() => {
        const updateServicesPerPage = () => {
            if (window.matchMedia('(max-width: 560px)').matches) {
                setServicesPerPage(1); // For screens smaller than 400px
            } else if (window.matchMedia('(max-width: 915px)').matches) {
                setServicesPerPage(2); // For screens smaller than 500px
            } else {
                setServicesPerPage(3); // Default for larger screens
            }
        };

        updateServicesPerPage(); // Set initially
        window.addEventListener('resize', updateServicesPerPage); // Listen for resize events

        return () => window.removeEventListener('resize', updateServicesPerPage); // Clean up listener
    }, []);

    useEffect(() => {
        const fetchServices = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await fetch('/dashboard', {
                    method: 'GET',
                    headers: {
                        'Authorization': `${token}`,
                    },
                });
                const data = await response.json();
                console.log(data,'data')
                setServices(data.featured_items);
                setOtherServices(data.other_items);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching services:', error);
                setLoading(false);
            }
        };

        fetchServices();
    }, []);

    const totalPages = Math.ceil(services.length / servicesPerPage);
    const indexOfLastService = currentPage * servicesPerPage;
    const indexOfFirstService = indexOfLastService - servicesPerPage;
    const currentServices = services.slice(indexOfFirstService, indexOfLastService);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

   
    const totalOtherPages = Math.ceil(otherServices.length / servicesPerPage);
    const indexOfLastOtherService = currentOtherPage * servicesPerPage;
    const indexOfFirstOtherService = indexOfLastOtherService - servicesPerPage;
    const currentOtherServices = otherServices.slice(indexOfFirstOtherService, indexOfLastOtherService);

    const paginateOtherServices = (pageNumber) => setCurrentOtherPage(pageNumber);


    return (
        <div className="LPApp">
        <Navbar />
            {loading && (
            <div className="modal-overlay">
                <div className="preloader-modal">
                    <div className="spinner"></div>
                </div>
            </div>
        )}
            <div className="LPMainContainer">
                <div className="LPFeaturedServices">
                    <div className="LPServicesHeading">
                        <span>Featured Services</span>
                        <p>Select from our featured services catered for every business.</p>
                    </div>

                    <div className="LPCardContainer">
                        {currentServices.map((service, index) => (
                            <Card key={index} service={service} />
                        ))}
                    </div>
                    <Pagination totalPages={totalPages} currentPage={currentPage} paginate={paginate} />
                </div>


                <div className="LPFeaturedServices">
                    <div className="LPServicesHeading">
                        <span>Other Services</span>
                        <p>Select from our other services that businesses around the world are using.</p>
                    </div>

                    <div className="LPCardContainer">
                        {currentOtherServices.map((service, index) => (
                            <Card key={index} service={service} />
                        ))}
                    </div>
                    <Pagination totalPages={totalOtherPages} currentPage={currentOtherPage} paginate={paginateOtherServices} />
                </div>

            </div>
        </div>
    );
};

export default LandingPage;
