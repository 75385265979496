import React, { useEffect, useState } from 'react';
import '../Cart/Cart.css';
import Navbar from "../Dashboard/Navbar";
import msicon from '../Assets/msicon.svg';
import buynow from '../Assets/buynow.svg';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Cart = () => {
    const [cartItems, setCartItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [dataFetched, setDataFetched] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true);
        const category = "CRT";
        const user = localStorage.getItem('userId');
        axios.get(`/api/get/orders?category=${category}&user=${user}`)
            .then(response => {
                const data = response.data;
                setCartItems(data);
                setDataFetched(true)
                console.log(data, 'data');
            })
            .catch(error => {
                console.error("There was an error fetching the subscriptions!", error);
            })
            .finally(() => {
                // Set loading to false once the data is fetched
                setLoading(false);
            });
    }, []);

    const handleBuyNowClick = (order_type, id) => {
            if (order_type==='NEW') {
          navigate(`/order/${id}`);
        } else if(order_type==="TUP") {
          navigate(`/contract/${id}`);
        }
      };

    return (
        <div className="landing-page">
            <Navbar />
            {loading ? (
                <div className="modal-overlay">
                    <div className="preloader-modal">
                        <div className="spinner"></div>
                    </div>
                </div>
            ) : (
                <div className="cart-subscriptions-container">
                    <div className='cart-title-adjust'>
                        <div className='cart-title-box'>
                            <h3 className="cart-title">Cart Items</h3>
                        </div>
                    </div>
                    <p className='sub-titl'>Review Cart Items and Continue</p>
                    <div className="cart-subscriptions-box">
                        <div className="cart-section">
                            <div className='cart-data-container'>
                            <div className="cart-header">
                                <div className="cart-header-item-service">Service Details</div>
                                <div className="cart-header-item1">Purchased Licenses</div>
                                <div className="cart-header-item2">Total Price</div>
                                <div className="cart-header-item2">Tax Amount</div>
                                <div className="cart-header-item2">Grand Total</div>
                                <div className="cart-header-item2">Order Type</div>
                                <div className="cart-header-item2">Purchase</div>
                            </div>
                            {cartItems && cartItems.length > 0 ? (
                                cartItems.map(item => {
                                    return (<div key={item.id} className="cart-subscription-item">
                                        <div className="cart-service-details">
                                            <img src={msicon} alt="service-icon" className="cart-service-icon" />
                                            <div className='details-p'>
                                                <p className="cart-service-name">{item.itemName}</p>
                                                <p className="cart-service-plan">
                                                    {item.commitment_plan && (
                                                        item.commitment_plan === "P1M" || item.commitment_plan === "M" 
                                                        ? "Monthly Plan" 
                                                        : item.commitment_plan === "A" || item.commitment_plan === "P1Y"
                                                        ? "Yearly Plan" 
                                                        : item.commitment_plan
                                                    )}
                                                    {item.billing_cycle && (
                                                        item.billing_cycle === "P1M" || item.billing_cycle === "M" 
                                                        ? "(Monthly billing)" 
                                                        : item.billing_cycle === "A" || item.billing_cycles === "P1Y"
                                                        ? "(Yearly billing)" 
                                                        : item.billing_cycle
                                                    )}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="cart-license-count">
                                            <span style={{ marginRight: '4px' }}>x</span>{item.quantity}
                                        </div>
                                        <div className="cart-expiring-on">{item.total}</div>
                                        <div className="cart-billing-date">{item.tax_amount}</div>
                                        <div className="cart-billing-date1">{item.grand_total}</div>
                                        <div className='cart-order-type'>{item.order_type} </div>
                                        <div className="cart-actions">
                                            <div 
                                                className='cart-S1frame26' 
                                                onClick={() => handleBuyNowClick(item.order_type, item.id)} // Pass order_type to the function
                                            >
                                                <img src={buynow} alt="Buy Now" />
                                            </div>
                                            </div>
                                    </div>
                                 );
                                })
                            ) : (
                                dataFetched ? (<div className="no-subscriptions">
                                    <h5>Carts not found.</h5>
                                </div>) : null
                            )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Cart;