import React from "react";
import logo  from '../Assets/logo.svg';
import './purchaceFlow.css';

const S3PaymentModal = ({ close }) => {
    return (
        <div className="S3ModalContainer">
            <div className="S3ModalHeader">
                
                <img src={logo}></img>
                <div className="S3CloseButton">
                    <span onClick={close} className="S3Icon">X</span>
                </div>
            </div>

            <div className="S3ModalContent">
                <div className="S3PaymentMethod">
                    <div className="S3MethodName">Preferred Payment Methods</div>
                    <div className="S3MethodOption">
                        
                        <div className="S3OptionIcon">
                            <div className="S3paymenttypeicon"> </div>
                            <span>UPI QR</span>
                        </div>
                        <div className="S3OptionChecks">
                            <div className="S3OptionCheck">
                                <img src="path-to-image1.jpg" alt="Image 1" />
                            </div>
                            <div className="S3OptionCheck">
                                <img src="path-to-image2.jpg" alt="Image 2" />
                            </div>
                            <div className="S3OptionCheck">
                                <img src="path-to-image3.jpg" alt="Image 3" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="S3PaymentMethod">
                    <div className="S3MethodName">Preferred Payment Methods</div>
                    <div className="S3MethodOption">
                        <div className="S3OptionIcon">
                            <div className="S3paymenttypeicon"> </div>
                            <span>CARD</span></div>
                            <div className="S3OptionChecks">
                            <div className="S3OptionCheck">
                                <img src="path-to-image1.jpg" alt="Image 1" />
                            </div>
                            <div className="S3OptionCheck">
                                <img src="path-to-image2.jpg" alt="Image 2" />
                            </div>
                            <div className="S3OptionCheck">
                                <img src="path-to-image3.jpg" alt="Image 3" />
                            </div>
                        </div>
                    </div>
                    <div className="S3MethodOption">
                        <div className="S3OptionIcon">
                            <div className="S3paymenttypeicon"> </div>
                            <span>UPI / QR</span>
                        </div>
                        <div className="S3OptionChecks">
                            <div className="S3OptionCheck">
                                <img src="path-to-image1.jpg" alt="Image 1" />
                            </div>
                            <div className="S3OptionCheck">
                                <img src="path-to-image2.jpg" alt="Image 2" />
                            </div>
                            <div className="S3OptionCheck">
                                <img src="path-to-image3.jpg" alt="Image 3" />
                            </div>
                        </div>
                    </div>
                    <div className="S3MethodOption">
                        <div className="S3OptionIcon">
                         
                            <div className="S3paymenttypeicon"> </div>
                            <span>Netbanking</span>
                        </div>
                        <div className="S3OptionChecks">
                            <div className="S3OptionCheck">
                                <img src="path-to-image1.jpg" alt="Image 1" />
                            </div>
                            <div className="S3OptionCheck">
                                <img src="path-to-image2.jpg" alt="Image 2" />
                            </div>
                            <div className="S3OptionCheck">
                                <img src="path-to-image3.jpg" alt="Image 3" />
                            </div>
                        </div>
                    </div>
                    <div className="S3MethodOption">
                        <div className="S3OptionIcon">
                           
                            <div className="S3paymenttypeicon"> </div>
                            <span> Wallet</span>
                        </div>
                        <div className="S3OptionChecks">
                            <div className="S3OptionCheck">
                                <img src="path-to-image1.jpg" alt="Image 1" />
                            </div>
                            <div className="S3OptionCheck">
                                <img src="path-to-image2.jpg" alt="Image 2" />
                            </div>
                            <div className="S3OptionCheck">
                                <img src="path-to-image3.jpg" alt="Image 3" />
                            </div>
                        </div>
                    </div>
                    <div className="S3MethodOption">
                        <div className="S3OptionIcon">
                            
                            <div className="S3paymenttypeicon"> </div>
                            <span> Pay Later</span>
                        </div>
                        <div className="S3OptionChecks">
                            <div className="S3OptionCheck">
                                <img src="path-to-image1.jpg" alt="Image 1" />
                            </div>
                            <div className="S3OptionCheck">
                                <img src="path-to-image2.jpg" alt="Image 2" />
                            </div>
                            <div className="S3OptionCheck">
                                <img src="path-to-image3.jpg" alt="Image 3" />
                            </div>
                        </div>
                    </div>
                </div>

               
            </div>

            <div className="S3ModalFooter">
                <div className="S3AmountDetails">
                    <div className="S3Amount">₹350</div>
                    <div className="S3Details">View Details</div>
                </div>
                <button className="S3PayButton">Pay Now</button>
            </div>
        </div>
    );
};

export default S3PaymentModal;
