import React, { useState, useEffect} from "react";
import CreateAccount from "../PurchaceFlow/CreateAccount";

import Navbar from "../Dashboard/Navbar";
// import '../App.css';
import '../RenewSubscription/RenewSubscription.css'
import troubleicon from '../Assets/troubleicon.svg';
import contactSupport from "../Assets/contactSupport.svg"
import AddSubscriptionForm from "./RenewsubscriptionForm";
import RenewSubscriptionForm from "./RenewsubscriptionForm";
import RenewSubscriptionReviewPlaceOrder from "./RenewSubscriptionPlaceOrder";
import RenewSubscriptionWelcomeOnboard from "./RenewSubscriptionClosure";
import { useNavigate, useParams, useLocation} from 'react-router-dom';

const RenewSubscription = () => {
    const [subscriptionData, setSubscriptionData] = useState({});
    const location = useLocation();
    const navigate = useNavigate();
    const [stage, setStage] = useState(1);
    const handleStageChange = (newData) => {
        setSubscriptionData(prevData => ({ ...prevData, ...newData }));
        setStage(stage + 1);
        window.scrollTo(0, 0);
    };
    
    const handleSetStage = (n) => {
        // if(n<stage){
            setStage(n);
        // }
        window.scrollTo(0, 0);
    };

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const queryStage = parseInt(queryParams.get('stage'), 10);

        if (!isNaN(queryStage)) {
            setStage(queryStage);
            navigate(location.pathname, { replace: true });
        }
    }, [location.search, navigate]);

    const stages = {
        1: <RenewSubscriptionForm onAction={handleStageChange} />,
        2: <RenewSubscriptionReviewPlaceOrder onAction={handleStageChange} subscriptionData={subscriptionData} />,
        3: <RenewSubscriptionWelcomeOnboard  />
    };

    const steps = [
        { title: "Renew Your Subscription", subtitle: "Set up your Subscription" },
        { title: "Place Order", subtitle: "Review and Place Order" },
        { title: "Order Processed", subtitle: "Fulfilment Pending" }
    ];
    

    return (
        <div className="landing-page">
            <Navbar />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-3 S1col-41 sidebar">
                        
                        <div className="RenewSub-LPnav-panel">
    <div className="RenewSub-LPnav-options">
        {steps.map((step, index) => (
            <React.Fragment key={index}>
                <div className="RenewSub-LPstep">
                    <div
                        className={`RenewSub-LPcircle ${index + 1 <= stage ? 'active' : ''}`}
                        onClick={() => handleSetStage(index + 1)}
                    >
                        <span className={`${index + 1 <= stage ? 'active' : ''}`}>{index + 1}</span>
                    </div>
                    <div className="RenewSub-LPstep-details">
                        <div className={`RenewSub-LPtitle ${index + 1 <= stage ? 'active' : ''}`}>{step.title}</div>
                        <div className={`RenewSub-LPsubtitle ${index + 1 <= stage ? 'active' : ''}`}>{step.subtitle}</div>
                    </div>
                </div>
                {index < steps.length - 1 && <div className="RenewSub-LPvertical-dashed-line"></div>}
            </React.Fragment>
        ))}
    </div>

    <div className="RenewSub-LPhelp-section">
        <img src={troubleicon} alt="Trouble Icon" />
        <div className="RenewSub-LPhelp-details">
            <div className="RenewSub-LPhelp-title">Having trouble?</div>
            <div className="RenewSub-LPhelp-description">Feel free to contact us and we will always help you through the process.</div>
        </div>
        <a href="https://www.briotech.com/contact-us/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
        <div className="RenewSub-LPcontact-button">
            <img src={contactSupport} alt="Contact Icon" />
            Contact Us
        </div>
        </a>
    </div>
</div>

                    </div>
                    <div className="col-9 S1col-81">
                        {stages[stage]}
                    </div>
                </div>
            </div>
        </div>
       
    );
};

export default RenewSubscription;
