import React, { useState, useEffect } from "react";
import CreateAccount from "../PurchaceFlow/CreateAccount";
import Navbar from "../Dashboard/Navbar";
// import '../App.css';
import "../AddLicences/AddLicenses.css";
import troubleicon from "../Assets/troubleicon.svg";
import contactSupport from "../Assets/contactSupport.svg"
import AddSubscriptionForm from "./AddsubscriptionForm";
import AddSubscriptionReviewPlaceOrder from "./AddSubscriptionPlaceOrder";
import AddSubscriptionWelcomeOnboard from "./AddSubscriptionClosure";
import { useNavigate, useParams, useLocation} from 'react-router-dom';

const AddSubscription = () => {
  // const [count, setCount] = useState(1);
  const { id} = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [subscriptionData, setSubscriptionData] = useState({});
  const [stage, setStage] = useState(1);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const handleStageChange = (newData) => {
        setSubscriptionData(prevData => ({ ...prevData, ...newData }));
        setStage(stage + 1);
        window.scrollTo(0, 0);
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const queryStage = parseInt(queryParams.get('stage'), 10);

    if (!isNaN(queryStage)) {
      setStage(queryStage);
      
      // Remove query parameter from the URL
      navigate(location.pathname, { replace: true });
    }
  }, [location.search, navigate]);


  const handleSetStage = (n) => {
    // if(n<stage){
    setStage(n);
    // }
    window.scrollTo(0, 0);
  };
  

  const stages = {
    1: <AddSubscriptionForm onAction={handleStageChange} subscriptionId={id}/>,
    2: <AddSubscriptionReviewPlaceOrder onAction={handleStageChange} subscriptionData={subscriptionData} />,
    3: <AddSubscriptionWelcomeOnboard subscriptionData={subscriptionData} />,
  };

  const steps = [
    { title: "Add More Licenses", subtitle: "Add more to your subscription" },
    { title: "Place Order", subtitle: "Review and Place Order" },
    { title: "Order Processed", subtitle: "Fulfilment Pending" },
  ];

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <div className="landing-page">
      <Navbar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
      <div className="container-fluid maincontent">
        <div className="row">
          {/* Sidebar should always be visible on desktop and toggle on mobile */}
          <div className={`col-3 S1col-412 sidebar ${sidebarOpen ? "open" : ""}`}>
            <div className="AddSub-LPnav-panel">
              <div className="AddSub-LPnav-options">
                {steps.map((step, index) => (
                  <React.Fragment key={index}>
                    <div className="AddSub-LPstep">
                      <div
                        className={`AddSub-LPcircle ${
                          index + 1 <= stage ? "active" : ""
                        }`}
                        onClick={() => handleSetStage(index + 1)}
                      >
                        <span
                          className={`${index + 1 <= stage ? "active" : ""}`}
                        >
                          {index + 1}
                        </span>
                      </div>
                      <div className="AddSub-LPstep-details">
                        <div
                          className={`AddSub-LPtitle ${
                            index + 1 <= stage ? "active" : ""
                          }`}
                        >
                          {step.title}
                        </div>
                        <div
                          className={`AddSub-LPsubtitle ${
                            index + 1 <= stage ? "active" : ""
                          }`}
                        >
                          {step.subtitle}
                        </div>
                      </div>
                    </div>
                    {index < steps.length - 1 && (
                      <div className="AddSub-LPvertical-dashed-line"></div>
                    )}
                  </React.Fragment>
                ))}
              </div>

              <div className="AddSub-LPhelp-section">
                <img src={troubleicon} alt="Trouble Icon" />
                <div className="AddSub-LPhelp-details">
                  <div className="AddSub-LPhelp-title">Having trouble?</div>
                  <div className="AddSub-LPhelp-description">
                    Feel free to contact us and we will always help you through
                    the process.
                  </div>
                </div>
                <a href="https://www.briotech.com/contact-us/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
                  <div className="AddSub-LPcontact-button">
                    <img src={contactSupport} alt="Contact Icon" />
                    Contact Us
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className={`col-lg-9 S1col-812 ${sidebarOpen ? "hidden-content" : ""}`}>
            {stages[stage]}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddSubscription;
