import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Navbar from '../Dashboard/Navbar';
import Card from '../Dashboard/Card';
import Pagination from '../Dashboard/Pagination';
import '../Dashboard/dashboard.css';

const SearchResults = () => {
    const servicesPerPage = 3;
    const location = useLocation();
    const [services, setServices] = useState(location.state?.services || []);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const selectedCountry = location.state?.selectedCountry || { name: 'India', icon: "" }; // Default to India

    useEffect(() => {
        setLoading(false);
    }, [services]);

    const totalPages = Math.ceil(services.length / servicesPerPage);
    const indexOfLastService = currentPage * servicesPerPage;
    const indexOfFirstService = indexOfLastService - servicesPerPage;
    const currentServices = services.slice(indexOfFirstService, indexOfLastService);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <div className="LPApp">
            <Navbar />
            {loading && (
                <div className="modal-overlay">
                    <div className="preloader-modal">
                        <div className="spinner"></div>
                    </div>
                </div>
            )}
            <div className="LPMainContainer">
                <div className="LPFeaturedServices">
                    <div className="LPServicesHeading">
                        <span>Featured Services</span>
                        <p>Select from our featured services catered for every business</p>
                    </div>

                    <div className="LPCardContainer">
                        {currentServices.map((service, index) => (
                            <Card key={index} service={service} />
                        ))}
                    </div>
                    <Pagination totalPages={totalPages} currentPage={currentPage} paginate={paginate} />
                </div>
            </div>
        </div>
    );
};

export default SearchResults;
