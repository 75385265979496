import React, { Component } from "react";
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import "./ExistingMSPage.css";
// import { Navbar, Nav, NavDropdown, Button } from 'react-bootstrap';

// import SubscriptionPage from '../SubscriptionPage'
import MainContent from './MainContent'

class MicrosoftSignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      selectedCountry: {
        name: 'India',
        icon: '/Assets/india.svg'
      },
      countries: [
        { name: 'India', icon: '/Assets/india.svg' },
        { name: 'USA', icon: '/Assets/usa.svg' },
        { name: 'UAE', icon: '/Assets/uae.svg' },
        { name: 'Saudi Arabia', icon: '/Assets/saudi.svg' },
      ]
    };
  }

  toggleDropdown = () => {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen
    }));
  };

  selectCountry = (country) => {
    this.setState({
      selectedCountry: country,
      isOpen: false
    });
  };

  render() {
    const { isOpen, selectedCountry, countries } = this.state;

    return (

        <div className="main-container">
          <header>
            {/* <img src={logo} alt="Logo" className="logo" /> */}


            <svg width="102" height="41" viewBox="0 0 102 41" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.1417 0C9.01928 0 0 9.01922 0 20.1416C0 31.2641 9.01928 40.2834 20.1417 40.2834C23.1464 40.2834 25.9934 39.6287 28.5565 38.4482C35.4776 35.2587 40.2833 28.2654 40.2833 20.1416C40.2845 9.01922 31.2652 0 20.1417 0ZM20.1417 5.18131C23.577 5.18131 26.3691 7.97317 26.3691 11.4086C26.3691 14.849 23.577 17.636 20.1417 17.636C16.7014 17.636 13.9143 14.8502 13.9143 11.4086C13.9155 7.97317 16.7014 5.18131 20.1417 5.18131ZM28.8306 32.3284H11.4527V19.8798H28.8306V32.3284Z" fill="black"/>
            <path d="M79.6931 12.1163C80.8432 12.1163 81.7755 11.184 81.7755 10.034C81.7755 8.88406 80.8432 7.95178 79.6931 7.95178C78.5431 7.95178 77.6108 8.88406 77.6108 10.034C77.6108 11.184 78.5431 12.1163 79.6931 12.1163Z" fill="black"/>
            <path d="M81.7756 30.1615C81.7756 31.3117 80.8433 32.2439 79.6933 32.2439C78.5432 32.2439 77.6099 31.3117 77.6099 30.1615V16.8957C77.6099 15.7457 78.542 14.8134 79.6921 14.8134C80.8421 14.8134 81.7744 15.7457 81.7744 16.8957L81.7756 30.1615Z" fill="black"/>
            <path d="M53.1662 14.8126C51.4951 14.8126 49.94 15.291 48.6139 16.1058V10.0349C48.6139 8.88497 47.6816 7.9527 46.5315 7.9527C45.3815 7.9527 44.4492 8.88497 44.4492 10.0349V23.5297C44.4492 23.5357 44.4504 23.5419 44.4517 23.548C44.4626 28.3513 48.3568 32.2455 53.1675 32.2455C54.4655 32.2455 55.6963 31.9605 56.8048 31.4515C59.8035 30.069 61.8797 27.0422 61.8797 23.5285C61.8773 18.7178 57.9781 14.8126 53.1662 14.8126ZM55.0626 27.6649C54.4864 27.9341 53.8429 28.0784 53.1662 28.0784C50.6532 28.0784 48.6199 26.0487 48.6126 23.5419C48.6126 23.5382 48.6139 23.5345 48.6139 23.5297V23.493C48.6334 20.9972 50.6606 18.9797 53.1662 18.9797C55.678 18.9797 57.7151 21.0167 57.7151 23.5285C57.7151 25.3648 56.6298 26.948 55.0626 27.6649Z" fill="black"/>
            <path d="M92.9579 14.8116C88.1411 14.8116 84.2407 18.7169 84.2407 23.5288C84.2407 28.3405 88.1399 32.2458 92.9579 32.2458C94.2559 32.2458 95.4868 31.9608 96.5952 31.4517C99.5939 30.0693 101.67 27.0425 101.67 23.5288C101.669 18.7169 97.7696 14.8116 92.9579 14.8116ZM94.8543 27.664C94.2779 27.9331 93.6345 28.0775 92.9579 28.0775C90.4461 28.0775 88.4029 26.0404 88.4029 23.5288C88.4029 21.017 90.4449 18.9799 92.9579 18.9799C95.4697 18.9799 97.5066 21.017 97.5066 23.5288C97.5066 25.3639 96.4215 26.9421 94.8543 27.664Z" fill="black"/>
            <path d="M73.06 14.8144C68.2469 14.8144 64.3442 18.716 64.3442 23.5302V30.1627C64.3442 31.3126 65.2765 32.2449 66.4265 32.2449C67.5766 32.2449 68.5087 31.3126 68.5087 30.1627V23.5302C68.5087 21.0173 70.5458 18.9803 73.0588 18.9803C74.2089 18.9803 75.1412 18.048 75.1412 16.8979C75.1435 15.7467 74.2102 14.8144 73.06 14.8144Z" fill="black"/>
            </svg>
            <div className="header-buttons">
              <div className="country-selector">
                <div className="dropdown-header" onClick={this.toggleDropdown}>
                  <div className="country-icon" style={{ backgroundImage: `url(${selectedCountry.icon})` }} />
                  <span className="country-name">{selectedCountry.name}</span>
                  <div className={`arrow-icon ${isOpen ? 'open' : ''}`} />
                </div>
                {isOpen && (
                  <div className="dropdown-body">
                    {countries.map(country => (
                      <div
                        key={country.name}
                        className="dropdown-item"
                        onClick={() => this.selectCountry(country)}
                      >
                        <div className="country-icon" style={{ backgroundImage: `url(${country.icon})` }} />
                        <span className="country-name">{country.name}</span>
                        {selectedCountry.name === country.name ? (
                          <img src='/Assets/righticon1.svg' alt='right' />
                        ) : <div className="check-icon1" />}
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <button className="primary-button">
                <div className="icon-container">
                  {/* Replace this with your actual icon */}
                  {/* <img src="loginicon.svg" alt="Icon" className="icon" /> */}
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_64_14264)">
<path d="M18.6665 2.66669H7.99984C7.64622 2.66669 7.30708 2.80716 7.05703 3.05721C6.80698 3.30726 6.6665 3.6464 6.6665 4.00002V8.66669H12.3332L10.0798 6.47335C9.97062 6.34582 9.91355 6.18177 9.92003 6.01398C9.92651 5.8462 9.99607 5.68704 10.1148 5.56831C10.2335 5.44958 10.3927 5.38003 10.5605 5.37355C10.7283 5.36706 10.8923 5.42414 11.0198 5.53335L14.8798 9.39335L11.0198 13.2534C10.8923 13.3626 10.7283 13.4196 10.5605 13.4132C10.3927 13.4067 10.2335 13.3371 10.1148 13.2184C9.99607 13.0997 9.92651 12.9405 9.92003 12.7727C9.91355 12.6049 9.97062 12.4409 10.0798 12.3134L12.3332 10H6.6665V20C6.6665 20.3536 6.80698 20.6928 7.05703 20.9428C7.30708 21.1929 7.64622 21.3334 7.99984 21.3334H18.6665C19.0201 21.3334 19.3593 21.1929 19.6093 20.9428C19.8594 20.6928 19.9998 20.3536 19.9998 20V4.00002C19.9998 3.6464 19.8594 3.30726 19.6093 3.05721C19.3593 2.80716 19.0201 2.66669 18.6665 2.66669Z" fill="#1A1A1A"/>
<path d="M6.66667 8.66669H2.66667C2.48986 8.66669 2.32029 8.73693 2.19526 8.86195C2.07024 8.98697 2 9.15654 2 9.33335C2 9.51016 2.07024 9.67973 2.19526 9.80476C2.32029 9.92978 2.48986 10 2.66667 10H6.66667V8.66669Z" fill="#1A1A1A"/>
</g>
<defs>
<clipPath id="clip0_64_14264">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>



                </div>
                <span className="button-text">Login</span>
              </button>
            </div>
          </header>
          {/* <SubscriptionPage/> */}

          <div className="content1">
          <MainContent /> {/* Add MainContent component here */}
        </div>
        </div>

    );
  }
}

export default MicrosoftSignIn;
