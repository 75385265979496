import React from 'react';
import './Pagination.css';
import paginationicon from '../Assets/paginationicon.svg';
import paginationinactive from '../Assets/paginationinactive.svg';


const Pagination = ({ totalPages, currentPage, paginate }) => {
    const pageNumbers = [];

    for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
    }

    return (
        <nav className="pagination-nav">
            <button
                className="pagination-button"
                onClick={() => paginate(currentPage > 1 ? currentPage - 1 : 1)}
                disabled={currentPage === 1}
            >
                &lt;
            </button>
            <ul className="pagination">
                {pageNumbers.map(number => (
                    <li key={number} className={`page-item ${number === currentPage ? 'active' : ''}`}>
                        <button onClick={() => paginate(number)} className="page-link1">
                            {number === currentPage ? <img src={paginationicon} /> : <img src={paginationinactive} />}
                        </button>
                    </li>
                ))}
            </ul>
            <button
                className="pagination-button"
                onClick={() => paginate(currentPage < totalPages ? currentPage + 1 : totalPages)}
                disabled={currentPage === totalPages}
            >
                &gt;
            </button>
        </nav>
    );
};

export default Pagination;
