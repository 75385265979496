import React, { useState } from "react";
import SubscriptionForm from "./SubscriptionDetails";
import ReviewPlaceOrder from "./ReviewPlaceOrder";
import Navbar from "../Dashboard/Navbar";
import './purchaceFlow.css';
import troubleicon from '../Assets/troubleicon.svg';
import contactSupport from "../Assets/contactSupport.svg"
import MicrosoftSignIn from "./MicrosoftSignin";
import WelcomeOnboard from "./Closure";
import CreateAccount from "./CreateAccount";
import "../AddLicences/AddLicenses.css";

const Subscription = () => {
    const [stage, setStage] = useState(1);
    const [formData, setFormData] = useState({}); // State to hold the data passed between stages
    const [sidebarOpen, setSidebarOpen] = useState(false);

    const handleStageChange = (data) => {
        debugger
        setFormData({
            ...formData,
            ...data,
        });
        setStage(stage + 1);
        window.scrollTo(0, 0);
    };

    const steps = [
        { title: "Subscription Details", subtitle: "Set up your Subscription" },
        { title: "Create Account", subtitle: "Enter your email and details" },
        { title: "Place Order", subtitle: "Review and Place Order" },
        { title: "Microsoft Sign-In", subtitle: "Sign-in to Microsoft 365" },
        { title: "Order Processed", subtitle: "Fulfilment Pending" }
    ];

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    const stages = {
        1: <SubscriptionForm onAction={handleStageChange} />,
        2: <CreateAccount onAction={handleStageChange} countryData={formData} />,
        3: <ReviewPlaceOrder onAction={handleStageChange} countryData={formData} />,
        4: <MicrosoftSignIn onAction={handleStageChange} countryData={formData} />,
        5: <WelcomeOnboard countryData={formData} />
    };

    return (
        <div className="landing-page">
            <Navbar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
            <div className="container-fluid maincontent">
                <div className="row">
                    <div className={`col-3 S1col-4 sidebar ${sidebarOpen ? "open" : ""}`}>
                        <div className="LPnav-panel">
                            <div className="LPnav-options">
                                {steps.map((step, index) => (
                                    <React.Fragment key={index}>
                                        <div className="LPstep">
                                            <div
                                                className={`LPcircle ${index + 1 <= stage ? 'active' : ''}`}
                                                // onClick={() => handleSetStage(index + 1)}
                                            >
                                                <span className={`${index + 1 <= stage ? 'active' : ''}`}>{index + 1}</span>
                                            </div>
                                            <div className="LPstep-details">
                                                <div className={`LPtitle ${index + 1 <= stage ? 'active' : ''}`}>{step.title}</div>
                                                <div className={`LPsubtitle ${index + 1 <= stage ? 'active' : ''}`}>{step.subtitle}</div>
                                            </div>
                                        </div>
                                        {index < steps.length - 1 && <div className="LPvertical-dashed-line"></div>}
                                    </React.Fragment>
                                ))}
                            </div>

                            <div className="LPhelp-section">
                                <img src={troubleicon} alt="Trouble Icon" />
                                <div className="LPhelp-details">
                                    <div className="LPhelp-title">Having trouble?</div>
                                    <div className="LPhelp-description">Feel free to contact us and we will always help you through the process.</div>
                                </div>
                                <a href="https://www.briotech.com/contact-us/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
                                    <div className="LPcontact-button">
                                    <img src={contactSupport} alt="Contact Icon" />
                                        Contact Us
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className={`col-9 S1col-8  ${sidebarOpen ? "hidden-content" : ""}`}>
                        {stages[stage]}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Subscription;
