import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import righticon1 from '../Pages/Assets/righticon1.svg';
import doticon from '../Pages/Assets/doticon.svg'
import buynow from '../Pages/Assets/buynow.svg';
import MicrosoftBusinessEssentials from '../Pages/Assets/BusinessEssentials.png'
import DefaultO365Image from '../Pages/Assets/defalut-office365.png' 
import BusinessBasic from '../Pages/Assets/BusinessBasic.png'
import BusinessStandard from '../Pages/Assets/BusinessStandard.png'
import BusinessPremium from '../Pages/Assets/BusinessPremium.png'
import './card.css';

function Card({ service, type = '' }) {
    const navigate = useNavigate();
    const location = useLocation();
    const selectedCountry = location.state?.selectedCountry || { name: 'India', icon: "" }; // Default to India
    const country_code = selectedCountry.name === "India" ? "IN" : selectedCountry.name === "USA" ? "US" : null;
    const currency = country_code === "IN" ? "INR" : country_code === "US" ? "USD" : null;
    const currencySymbols = {"INR": "₹","USD": "$"};
    const price_dict = service.price;
    const price = service.price[currency];
    const currencySymbol = currencySymbols[currency];
    const handleBuyNow = () => {
        localStorage.setItem("item", service.sku)
        navigate('/order', { state: { sku_id: service.sku, country: country_code } });
        window.scrollTo(0, 0);
    };
    const service_icons = {
        "BusinessEssentials": MicrosoftBusinessEssentials,
        "businessBasic": BusinessBasic,
        "businessStandard": BusinessStandard,
        "businessPremium": BusinessPremium,
    };
    return (
        <div className="item-card">
            <div className="item-card-header-1">
                <h3 className="item-serviceTitle">{service.name}</h3>
                <div className='item-serviceTitlebelow'>
                    <div className='part1'>Starting At </div>
                    {price_dict['ERP_INR'] && (<div style={{'color':'#b5b3b3', 'textDecorationLine':'line-through', 'fontSize':'18px', 'fontWeight':'bolder', 'paddingLeft':'5px', 'paddingRight':'5px'}}>{currencySymbol}{price_dict['ERP_INR']}</div>)}
                    <div className='part2'>{currencySymbol}{price}/Yearly</div>
                </div>
                
            </div>

            <div className="item-features">
                {service.images.map((feature, index) => (
                    <div key={index} className="item-feature">
                        <img src={service_icons[feature]?service_icons[feature]:DefaultO365Image} alt={feature} />
                    </div>
                ))}
            </div>

            <div className='item-descriptionHeading'>{service.description}:</div>
            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%', marginBottom:'10px'}}>
                <div className="item-Descriptions">
                    {service.features.map((description, index) => (
                        <div key={index} className='item-description'>
                            <img src={doticon} alt="dot icon" />
                            <span>{description}</span>
                        </div>
                    ))}
                </div>
                {type !== 'preview' && (
                    <div className="item-frame-24">
                        <div className="item-frame-65">
                            <span className="item-starting-at">Starting at</span>
                            <span className="item-price">{currencySymbol}{price}/Yearly</span>
                        </div>
                        <div className="item-frame-26" onClick={handleBuyNow}>
                            <span className="item-buy-now">Buy Now</span>
                            <img src={buynow} alt="buy now" />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Card;
