import React, {useEffect, useState} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import righticon1 from '../Assets/righticon1.svg';
import doticon from '../Assets/doticon.svg';
import buynow from '../Assets/buynow.svg';
import MicrosoftBusinessEssentials from '../Assets/BusinessEssentials.png'
import DefaultO365Image from '../Assets/defalut-office365.png' 
import BusinessBasic from '../Assets/BusinessBasic.png'
import BusinessStandard from '../Assets/BusinessStandard.png'
import BusinessPremium from '../Assets/BusinessPremium.png'
import './dashboard.css';

function Card({ service, type = '' }) {
    const navigate = useNavigate();
    const location = useLocation();
    const selectedCountry = location.state?.selectedCountry || { name: 'India', icon: "" };
    const queryParams = new URLSearchParams(location.search);
    const TUPValue = queryParams.get('TUP');
    const country_code = selectedCountry.name === "India" ? "IN" : selectedCountry.name === "USA" ? "US" : null;
    const currency = country_code === "IN" ? "INR" : country_code === "US" ? "USD" : null;
    const currencySymbols = {"INR": "₹","USD": "$"};
    const price_dict = service.price;
    const price = service.price[currency];
    const currencySymbol = currencySymbols[currency];
    const [isAuthenticated, setIsAuthenticated] = useState()
    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            setIsAuthenticated(true);
        } else {
            setIsAuthenticated(false);
        }
    }, []);
    const handleBuyNow = () => {
        localStorage.setItem("item", service.sku)
        if (TUPValue === "true"){
            navigate('/contract', { state: { sku_id: service.sku, country: country_code } });
        } else{
            navigate('/order', { state: { sku_id: service.sku, country: country_code } });
        }
        window.scrollTo(0, 0);
    };
    const service_icons = {
        "BusinessEssentials": MicrosoftBusinessEssentials,
        "businessBasic": BusinessBasic,
        "businessStandard": BusinessStandard,
        "businessPremium": BusinessPremium,
    };
    return (
        <div className="card">
            <div className="card-header-1">
                <h3 className="serviceTitle">{service.name}</h3>
                <div className='serviceTitlebelow'>
                    <div className='part1'>Starting At </div>
                    {price_dict['ERP_INR'] && (<div style={{'color':'#b5b3b3', 'textDecorationLine':'line-through', 'fontSize':'18px', 'fontWeight':'bolder', 'paddingLeft':'5px', 'paddingRight':'5px'}}>{currencySymbol}{price_dict['ERP_INR']}</div>)}
                    <div className='part2'>{currencySymbol}{price}/Yearly</div>
                </div>
                
            </div>

            <div className="features">
                {service.images.map((feature, index) => (
                    <div key={index} className="feature">
                        <img src={service_icons[feature]?service_icons[feature]:DefaultO365Image} alt={feature} />
                    </div>
                ))}
            </div>

            <div className='descriptionHeading'>{service.description}:</div>
            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%', marginBottom:'10px'}}>
                <div className="Descriptions">
                    {service.features.map((description, index) => (
                        <div key={index} className='description'>
                            <img src={doticon} alt="dot icon" />
                            <span>{description}</span>
                        </div>
                    ))}
                </div>
                {type !== 'preview' && (
                    <div className="frame-24">
                        <div className="frame-65">
                            <span className="starting-at">Starting at</span>
                            {price_dict['ERP_INR'] && (<div style={{'color':'#4F4F4F', 'textDecorationLine':'line-through', 'fontSize':'18px', 'fontWeight':'bolder', 'paddingLeft':'5px', 'paddingRight':'5px'}}>{currencySymbol}{price_dict['ERP_INR']}</div>)}
                            <span className="price">{currencySymbol}{price}/Yearly</span>
                        </div>
                        <div className="frame-26" onClick={handleBuyNow}>
                            <span className="buy-now">Buy Now</span>
                            <img src={buynow} alt="buy now" />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Card;
