import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import S3PaymentModal from '../PurchaceFlow/S3PaymentModel';
import '../RenewSubscription/RenewSubscription.css'
import { handlePayment } from '../razorpay';
import axios from 'axios';

const RenewSubscriptionReviewPlaceOrder = ({ onAction, subscriptionData }) => {
    const [showPopup, setShowPopup] = useState(false);
    const taxRate = subscriptionData.subDetailsDict.console === "US" ? 0 : 0.18;
    const count = subscriptionData.count
    console.log(subscriptionData, 'check.l.ll.l.l.l')
    const RenewDueAmount = parseFloat(subscriptionData.dueAmount.toFixed(2));
    const estimatedTaxes = parseFloat((RenewDueAmount * taxRate).toFixed(2));
    const totalAmount = Math.round(RenewDueAmount + estimatedTaxes);
    const [loading, setLoading] = useState(false);
    const currency = subscriptionData.subDetailsDict.currency
    const unit_price = subscriptionData.subDetailsDict.unit_price
    const icon = currency === "USD" ? '$' : '₹'
    const [showRetryButton, setShowRetryButton] = useState(false);
    const [captureDetails, setCaptureDetails] = useState(null);
    const [retryUrl, setRetryUrl] = useState('');
    const [razorpayId, setRazorpayId] = useState('');
    const customerAddress = subscriptionData.subDetailsDict.customer_address;
    const token = localStorage.getItem('token');
    const user = localStorage.getItem('userId');
    console.log(customerAddress,'customerAddress')

    const navigate = useNavigate();

    useEffect(() => {
        const fetchRazorpay = async () => {
            const response = await fetch('/get/stage', {
                method:'GET',
                headers:{
                    'Authorization': `${token}`,
                },
            });
            const data = await response.json();
            console.log(data)
            setRazorpayId(data.razorpay)
        }
        fetchRazorpay()
    },[])

    const handlenextpage = () => {
        navigate('/AddSubscriptionStage3');
        window.scrollTo(0, 0);
    };

    const handlePlaceOrderClick = () => {
        subscriptionData.subDetailsDict.taxRate = taxRate;
        subscriptionData.subDetailsDict.RenewDueAmount = RenewDueAmount;
        subscriptionData.subDetailsDict.totalAmount = totalAmount;
        subscriptionData.subDetailsDict.count = count;
        subscriptionData.subDetailsDict.tax_price = estimatedTaxes.toFixed(2);
        subscriptionData.name = customerAddress["first_name"] + " " + customerAddress["last_name"];
        subscriptionData.email = customerAddress["email"];
        subscriptionData.phone = customerAddress["phone"];
        subscriptionData.userId = user;
        // setLoading(true)
        handlePayment(handlePaymentSuccess, handlePaymentFailure, 'renewSubscription', subscriptionData.subDetailsDict, setLoading, razorpayId);

    };
    const handleClosePopup = () => {
        setShowPopup(false);
    };

    const handlePaymentSuccess = (response) => {
        onAction();
    };


    const handlePaymentFailure = (details, message, url) => {
        setLoading(false)
        console.error(message);
        setCaptureDetails(details);
        setRetryUrl(url);
        setShowRetryButton(true);
    };


    const handleRetryClick = async () => {
        if (captureDetails) {
            setLoading(true);
            try {
                const result = await axios.post(retryUrl, captureDetails);
                if (result.data.status) {
                    onAction();
                    setLoading(false);
                } else {
                    console.error('Retry failed: Backend validation failed.');
                    setLoading(false);
                }
            } catch (error) {
                console.error('Retry failed:', error);
                setLoading(false);
            }
        }
    };

    return (
        <>
            {loading && (
                <div className="modal-overlay">
                    <div className="preloader-modal">
                        <div className="spinner"></div>
                    </div>
                </div>
            )}
            <div className="RenewSubPlOd-S3main-content">
                <div className="RenewSubPlOd-S3frame-12">
                    <div className="RenewSubPlOd-S3frame-27">
                        <span>Review and Place Order</span>
                        <p className="RenewSub-S3check-fields">It is wise to check every field before placing the order.</p>
                    </div>
                    <div className="RenewSubPlOd-S3Fields">
                        <div className="RenewSubPlOd-S3Frame">
                            <div className='RenewSubPlOd-S3Frame1'>
                                <div className='RenewSubPlOd-S3Framecolumn'>
                                    <span className="RenewSubPlOd-S3Label">Product Name:</span>
                                    <span className="RenewSubPlOd-S3Value">{subscriptionData.subDetailsDict.item_name}</span>
                                </div>
                                <div className='RenewSubPlOd-S3Framecolumn'>
                                    <span className="RenewSubPlOd-S3Label">Billing Commitment:</span>
                                    <span className="RenewSubPlOd-S3Value">{subscriptionData.subDetailsDict.actuallBillingCycle === 'M' ? 'Monthly' : 'Annually'}</span>
                                </div>
                            </div>
                            <div className='RenewSubPlOd-S3Frame1'>
                                <div className="RenewSubPlOd-S3Framecolumn">
                                    <span className="RenewSubPlOd-S3Label">Price:</span>
                                    <span className="RenewSubPlOd-S3Value">{icon}{unit_price}</span>
                                </div>
                                <div className="RenewSubPlOd-S3Framecolumn">
                                    <span className="RenewSubPlOd-S3Label">Quantity:</span>
                                    <span className="RenewSubPlOd-S3Value">X {count}</span>
                                </div>
                            </div>
                            <div className='RenewSubPlOd-S3Frame1'>
                                <div className="RenewSubPlOd-S3Framecolumn">
                                    <span className="RenewSubPlOd-S3Label">Billing Plan:</span>
                                    <span className="RenewSubPlOd-S3Value">{subscriptionData.subDetailsDict.billing_cycle === 'M' ? 'Monthly' : 'Annually'}</span>
                                </div>
                                <div className="RenewSubPlOd-S3Framecolumn">
                                    <span className="RenewSubPlOd-S3Label">Subtotal:</span>
                                    <span className="RenewSubPlOd-S3Value">{icon}{subscriptionData.dueAmount} (tax not included)</span>
                                </div>
                            </div>

                        </div>

                        <div className="RenewSubPlOd-S3Frame RenewSubPlOd-S3FrameMiddle">
                            <div className="RenewSubPlOd-S3Frame1">
                                <div className="RenewSubPlOd-S3Framecolumn">
                                    <span className="RenewSubPlOd-S3Label">Sold to Address:</span>
                                    <span className="RenewSubPlOd-S3Value">
                                        {subscriptionData.subDetailsDict.customer_address.add1}<br />
                                        {subscriptionData.subDetailsDict.customer_address.add2}<br />
                                        {`${subscriptionData.subDetailsDict.customer_address.city}, ${subscriptionData.subDetailsDict.customer_address.state}, ${subscriptionData.subDetailsDict.customer_address.postal_code}`}, {subscriptionData.subDetailsDict.customer_address.country}<br />
                                        {subscriptionData.subDetailsDict.customer_address.gst_code}
                                    </span>
                                </div>
                            </div>
                        </div>
                        {/* <div className='S3Frame'>
                        <div className="S3Frame1">
                            <div className="S3Framecolumn">
                                <span className="S3Value">
                                <p>When your subscription starts, we'll charge you <b>{icon}{RenewDueAmount.toFixed(2)}/{subscriptionData.subDetailsDict.billing_cycle === 'M' ? 'Monthly' : 'Annually'}</b> plus applicable taxes every month. Charges will be included 
                                on the first invoice after the subscription starts. You'll be notified before any price changes. Cancel within 7 days of purchase for a prorated refund by visiting your Brio CSP account. Refunds aren't available after 7 days.
                                <a href="https://learn.microsoft.com/en-us/microsoft-365/commerce/subscriptions/cancel-your-subscription?view=o365-worldwide"target="_blank" rel="noopener noreferrer">Learn more about cancelling</a>.</p><br />
                                <p>Read our <a href="https://privacy.microsoft.com/en-us/privacystatement"target="_blank" rel="noopener noreferrer">privacy statement</a> for information on how your data is handled. By selecting accept agreement & place order, you agree to the terms and conditions in the <a href="https://www.microsoft.com/licensing/docs/customeragreement"target="_blank" rel="noopener noreferrer">Microsoft Customer Agreement</a>.</p><br />
                                <p>New Global, Billing, and Reader administrators will be granted permission to the Brio Billing account. You can disable the permissions after sign-up.</p>
                                </span>
                            </div>
                        </div>
                    </div> */}
                        <div className="S3Frame">
                            <div className="S3Frame1">
                                <div className="S3Framecolumn">
                                    <span className="S3Label">Annual commitment, billed monthly (before tax):</span>
                                </div>
                                <div className="S3FrameAmount">
                                    <span >{icon}{RenewDueAmount.toFixed(2)}</span>
                                </div>
                            </div>
                            {taxRate > 0 && (
                                <div className="S3Frame1">
                                    <div className="S3Framecolumn">
                                        <span className="S3Label">Estimate taxes:</span>
                                    </div>
                                    <div className="S3FrameAmount">
                                        <span >{icon}{estimatedTaxes.toFixed(2)}</span>
                                    </div>
                                </div>
                            )}
                            <div className="S3Frame1">
                                <div className="S3Framecolumn">
                                    <span className="S3Label">Total (monthly):</span>
                                </div>
                                <div className="S3FrameAmount">
                                    <span >{icon}{totalAmount}</span>
                                </div>
                            </div>

                        </div>
                        <div className="RenewSubPlOd-S3Footer">
                            <div className="RenewSubPlOd-S3FooterContent">
                                <span>
                                    {showRetryButton
                                        ? "Your payment has been completed. Please click on Submit to complete your order."
                                        : "By placing an order, you are accepting our terms and conditions."
                                    }
                                </span>
                                {showRetryButton ? (
                                    <div onClick={handleRetryClick}>
                                        <button className="RenewSubPlOd-S3PlaceOrderButton">
                                            Submit
                                        </button>
                                    </div>
                                ) : (
                                    <div onClick={handlePlaceOrderClick}>
                                        <button className="RenewSubPlOd-S3PlaceOrderButton">
                                            Place Order
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                {showPopup && (
                    <div className="RenewSubPlOd-S3popup-overlay">
                        <S3PaymentModal close={handleClosePopup} />
                    </div>
                )}
            </div>
        </>

    );
};

export default RenewSubscriptionReviewPlaceOrder;
