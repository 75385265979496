import React from 'react';
import { Link } from 'react-router-dom';
// import '../PurchaceFlow/WelcomeOnboard.css';
import '../AddLicences/AddLicenses.css'
import checkmarkicon from '../Assets/checkmarkicon.svg';
import buynow from '../Assets/buynow.svg';


const AddSubscriptionWelcomeOnboard = (subscriptionData) => {

  return (
    <div className="AddSubClo-welcomeContainer">
    <div className="AddSubClo-welcomeHeader">
      <div
        className="AddSubClo-checkmarkIcon"
        style={{ backgroundImage: `url(${checkmarkicon})` }}
      ></div>
      <h2 className="AddSubClo-welcomeTitle">License Added</h2>
    </div>
  
    <div className="AddSubClo-welcomeFooter">
    <p style={{textAlign:'left'}}>
        We are happy to inform you that {subscriptionData.subscriptionData.count} licenses have been successfully added to your {subscriptionData.subscriptionData.itemName} subscription. 
        We are committed to providing you with the best service and support for your business needs. If you have any questions or need further assistance, feel free to contact us anytime.
      </p>
      <div className="AddSubClo-buttonsContainer">
      <a href='/Home' className="exploreButton" style={{textDecoration: 'underline'}} target="_blank" rel="noopener noreferrer">
          <div>Explore Services</div>
        </a>
        <a href='/subscriptions' className="exploreButton" style={{textDecoration: 'none', border:'1px solid #000'}} target="_blank" rel="noopener noreferrer">
            <div>Manage Subscriptions</div>
        </a>
      </div>
    </div>
  </div>
  
  );
};

export default AddSubscriptionWelcomeOnboard;

