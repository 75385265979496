import React from 'react';
import { Link } from 'react-router-dom';
// import '../PurchaceFlow/WelcomeOnboard.css';
import '../AddLicences/AddLicenses.css'
import checkmarkicon from '../Assets/checkmarkicon.svg';
import buynow from '../Assets/buynow.svg';


const AddSubscriptionWelcomeOnboard = (itemName) => {
  const data = itemName.itemName.itemName

  return (
    // <div className="welcomeContainer">
    //   <div className="welcomeHeader">
    //     <div className="checkmarkIcon" style={{ backgroundImage: `url(${checkmarkicon})` }} ></div>
    //     <h2 className="welcomeTitle">Welcome Onboard!</h2>
    //   </div>
      
      
    //   <div className="welcomeFooter">
     
    //     <p>Your Order is Processed. Invoice will be sent to your registered email ID. Sit back and relax, we are working on your order fulfillment. You will be notified once it is fulfilled. Meanwhile, you can login or explore other services.</p>
    //     <div className="buttonsContainer">
    //     <Link to='/Home' className="exploreButton" style={{textDecoration:'none'}}>
    //         <div >Explore Services</div>
    //     </Link>
    //       <Link to='/login' style={{textDecoration:'none'}}>
    //        <div className="loginButton">
    //          <span>Login</span>
    //          <img src={buynow} />
    //        </div>
    //       </Link>
    //     </div>
    //   </div>
    // </div>
    <div className="AddSubClo-welcomeContainer" style={{marginTop:'8%'}} >
    <div className="AddSubClo-welcomeHeader">
      <div
        className="AddSubClo-checkmarkIcon"
        style={{ backgroundImage: `url(${checkmarkicon})` }}
      ></div>
      <h2 className="AddSubClo-welcomeTitle">Subscription Added</h2>
    </div>
  
    <div className="AddSubClo-welcomeFooter">
      <p style={{textAlign:'left'}}>
      We are pleased to inform you that your subscription to {data} has been successfully activated. 
      We are committed to providing you with the best service and support for your business needs. If you have any questions or need further assistance, feel free to contact us anytime.
      </p>
      <div className="AddSubClo-buttonsContainer">
        <a href='/Home' className="exploreButton" style={{textDecoration: 'underline'}} target="_blank" rel="noopener noreferrer">
          <div>Explore Services</div>
        </a>
        <a href='/subscriptions' className="exploreButton" style={{textDecoration: 'none', border:'1px solid #000'}} target="_blank" rel="noopener noreferrer">
            <div>Manage Subscriptions</div>
        </a>
      </div>
    </div>
  </div>
  
  );
};

export default AddSubscriptionWelcomeOnboard;

