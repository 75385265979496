import React, { useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

function ProtectedRoute({ element: Component, ...rest }) {
    const [isAuthenticated, setIsAuthenticated] = useState(null);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const path = location.pathname;
        const query = new URLSearchParams(location.search);
        const tokenFromQuery = query.get('jwt-token');

        // Process the token only if the route is '/'
        if (path === '/' && tokenFromQuery) {
            // Store the token in localStorage
            localStorage.setItem('token', tokenFromQuery);

            // Remove the jwt-token parameter from the URL
            query.delete('jwt-token');
            navigate(`${path}?${query.toString()}`, { replace: true });
        }

        const token = localStorage.getItem('token');
        if (token) {
            // Make a GET request to validate the token
            fetch('/api/validate-token', {
                method: 'GET',
                headers: {
                    'Authorization': `${token}`,
                },
            })
            .then(response => response.json())
            .then(data => {
                if (data.valid) {
                    setIsAuthenticated(true);
                } else {
                    setIsAuthenticated(false);
                    localStorage.removeItem('token'); // Remove invalid token
                }
            })
            .catch(error => {
                console.error('Error validating token:', error);
                setIsAuthenticated(false);
            });
        } else {
            setIsAuthenticated(false);
        }
    }, [location, navigate]);

    if (isAuthenticated === null) {
        // Optionally, render a loading indicator while checking authentication
        return (
            <div className="modal-overlay">
                <div className="preloader-modal">
                    <div className="spinner"></div>
                </div>
            </div>
        );
    }

    return isAuthenticated ? <Component {...rest} /> : <Navigate to="/login" />;
}

export default ProtectedRoute;
