// MainContent.js
import React from 'react';
import './MainContent.css'; // Add any CSS specific to MainContent

class MainContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: ''
        };
    }
    handleInputChange = (event) => {
        this.setState({ username: event.target.value });
    };
  render() {
    return (
        <div className='main-cntnt'>
        <div className="frame12">
            <div className="frame27">
            <div className="microsoftSignIn">Microsoft Sign-In</div>
            </div>
            <div className="signInText">
            Select how you prefer to sign-in to Microsoft 365555
            </div>
       
      </div>
      <div class="fields">
       
   
           
          
<div className='sanjana'> 
Do you have a Microsoft 365 Account which you want to add to this subscription?
</div>
            
            <div class="demo">
                
                <div class="demo1">
                    {/* <!-- I don't have a Microsoft account... --> */}
                    <div class="noAccount">
                        <div class="iconnnnnnn">
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="32" height="32" rx="16" fill="#FFF200"/>
<path d="M19 18C16.33 18 11 19.33 11 22V24H27V22C27 19.33 21.67 18 19 18ZM10 14V11H8V14H5V16H8V19H10V16H13V14M19 16C20.0609 16 21.0783 15.5786 21.8284 14.8284C22.5786 14.0783 23 13.0609 23 12C23 10.9391 22.5786 9.92172 21.8284 9.17157C21.0783 8.42143 20.0609 8 19 8C17.9391 8 16.9217 8.42143 16.1716 9.17157C15.4214 9.92172 15 10.9391 15 12C15 13.0609 15.4214 14.0783 16.1716 14.8284C16.9217 15.5786 17.9391 16 19 16Z" fill="#1A1A1A"/>
</svg>

                        </div>

                        <div class="text">
                         
                            I don't have a Microsoft account or use any Microsoft services like Power BI, 365, Azure, etc.
                        </div>
                    </div>
                </div>

                {/* <!-- Frame 3 --> */}
                <div class="demo1">
                    {/* <!-- I have a Microsoft account... --> */}
                    <div class="yesAccount">
                        <div className='iconnnnnnn'>
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.9458 1.70337L13.7924 1.79708C13.5511 1.94475 13.321 2.10945 13.1052 2.28836L13.5482 1.97882H17.2967L17.9782 7.13864L14.5705 10.5463L11.1628 12.9147V15.6465C11.1628 17.5548 12.1596 19.324 13.7924 20.3094L17.379 22.4818L9.79976 26.9033H8.33729L5.61398 25.2534C3.98112 24.2651 2.98438 22.4988 2.98438 20.5905V11.4068C2.98438 9.49846 3.98112 7.72647 5.61398 6.74107L13.7924 1.79424C13.8436 1.76206 13.8947 1.73177 13.9458 1.70337Z" fill="url(#paint0_radial_64_14333)"/>
<path d="M13.9458 1.70337L13.7924 1.79708C13.5511 1.94475 13.321 2.10945 13.1052 2.28836L13.5482 1.97882H17.2967L17.9782 7.13864L14.5705 10.5463L11.1628 12.9147V15.6465C11.1628 17.5548 12.1596 19.324 13.7924 20.3094L17.379 22.4818L9.79976 26.9033H8.33729L5.61398 25.2534C3.98112 24.2651 2.98438 22.4988 2.98438 20.5905V11.4068C2.98438 9.49846 3.98112 7.72647 5.61398 6.74107L13.7924 1.79424C13.8436 1.76206 13.8947 1.73177 13.9458 1.70337Z" fill="url(#paint1_linear_64_14333)"/>
<path d="M29.5648 17.3617L30.2464 18.384V20.5905C30.2464 22.496 29.2496 24.2651 27.6168 25.2534L19.4383 30.2059C17.7032 31.2566 15.528 31.2566 13.7929 30.2059L5.80469 25.3698C7.50569 26.3097 9.58723 26.2728 11.2598 25.259L19.4383 20.3094C21.0712 19.3211 22.0679 17.552 22.0679 15.6437V12.5909L29.5648 17.3617Z" fill="url(#paint2_radial_64_14333)"/>
<path d="M29.5648 17.3617L30.2464 18.384V20.5905C30.2464 22.496 29.2496 24.2651 27.6168 25.2534L19.4383 30.2059C17.7032 31.2566 15.528 31.2566 13.7929 30.2059L5.80469 25.3698C7.50569 26.3097 9.58723 26.2728 11.2598 25.259L19.4383 20.3094C21.0712 19.3211 22.0679 17.552 22.0679 15.6437V12.5909L29.5648 17.3617Z" fill="url(#paint3_linear_64_14333)"/>
<path d="M30.2462 11.4068V20.5905C30.2462 20.6492 30.2452 20.7088 30.2433 20.7694C30.1837 18.9292 29.1983 17.2396 27.6166 16.2826L19.4381 11.3358C17.703 10.2851 15.5278 10.2851 13.7927 11.3358L11.1631 12.926V6.46277C11.1631 4.55446 12.1598 2.7853 13.7927 1.79707L13.9546 1.69768C15.6641 0.743529 17.757 0.777606 19.4381 1.79423L27.6166 6.74107C29.2494 7.72646 30.2462 9.49846 30.2462 11.4068Z" fill="url(#paint4_radial_64_14333)"/>
<path d="M30.2462 11.4068V20.5905C30.2462 20.6492 30.2452 20.7088 30.2433 20.7694C30.1837 18.9292 29.1983 17.2396 27.6166 16.2826L19.4381 11.3358C17.703 10.2851 15.5278 10.2851 13.7927 11.3358L11.1631 12.926V6.46277C11.1631 4.55446 12.1598 2.7853 13.7927 1.79707L13.9546 1.69768C15.6641 0.743529 17.757 0.777606 19.4381 1.79423L27.6166 6.74107C29.2494 7.72646 30.2462 9.49846 30.2462 11.4068Z" fill="url(#paint5_linear_64_14333)"/>
<defs>
<radialGradient id="paint0_radial_64_14333" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(12.1283 6.89158) scale(22.7407)">
<stop offset="0.064" stop-color="#AE7FE2"/>
<stop offset="1" stop-color="#0078D4"/>
</radialGradient>
<linearGradient id="paint1_linear_64_14333" x1="12.1937" y1="25.4493" x2="8.94784" y2="19.838" gradientUnits="userSpaceOnUse">
<stop stop-color="#114A8B"/>
<stop offset="1" stop-color="#0078D4" stop-opacity="0"/>
</linearGradient>
<radialGradient id="paint2_radial_64_14333" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(7.36655 24.4156) scale(21.1618)">
<stop offset="0.134" stop-color="#D59DFF"/>
<stop offset="1" stop-color="#5E438F"/>
</radialGradient>
<linearGradient id="paint3_linear_64_14333" x1="27.7644" y1="16.9358" x2="24.2857" y2="21.9223" gradientUnits="userSpaceOnUse">
<stop stop-color="#493474"/>
<stop offset="1" stop-color="#8C66BA" stop-opacity="0"/>
</linearGradient>
<radialGradient id="paint4_radial_64_14333" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(28.2385 17.7053) scale(16.9845 16.9845)">
<stop offset="0.058" stop-color="#50E6FF"/>
<stop offset="1" stop-color="#436DCD"/>
</radialGradient>
<linearGradient id="paint5_linear_64_14333" x1="11.8276" y1="1.72608" x2="16.9476" y2="1.72608" gradientUnits="userSpaceOnUse">
<stop stop-color="#2D3F80"/>
<stop offset="1" stop-color="#436DCD" stop-opacity="0"/>
</linearGradient>
</defs>
</svg>
</div>
                        {/* <div class="icon microsoft-365"></div> */}
                        <div class="text">
                            I have a Microsoft account and want to add this subscription to my existing account.
                        </div>
                    </div>
                </div>
            </div>
            <div class="frame49">
            {/* <!-- How will you sign to Microsoft 365 account --> */}
            <div class="signInMethod">
                How will you sign to Microsoft 365 account
            </div>

            {/* <!-- Additional Information --> */}
            <div class="additionalInfo">
                Lorem ipsum dolor sit amet consectetur. Amet tempor ipsum mi sed. Vitae felis sit nisl turpis mauris. Tincidunt est lobortis lacus maecenas eget odio adipiscing consectetur felis. Massa in aliquet enim suscipit porttitor id augue quam tempor. Tellus turpis diam aliquet sodales aliquet mattis. Eget egestas cursus id nunc amet vestibulum. Sed dictum elementum nisi sit adipiscing etiam pharetra. Sit tellus aliquet placerat dui pellentesque eu fames felis aenean.
            </div>
        </div>

        
            {/* <!-- Frame 25 --> */}
            <div class="input-flds">
                {/* <!-- Frame 15 --> */}
                <div class="frame15">
                    <div class="usernameLabel">Username</div>
                    <div class="usernameField">
                        <input type="text" placeholder="Adam" />
                    </div>
                </div>

                {/* <!-- Frame 27 --> */}
                <div class="frame27">
                    {/* <!-- Domain Name --> */}
                    <div class="domainNameLabel">Domain Name</div>
                    <div class="domainNameField">
                        <input type="text" placeholder="abcd"   value={this.state.username}
                        onChange={this.handleInputChange}/>
                        <span className="domain">@onmicrosoft.com</span>
                    </div>
                </div>
            </div>
           <div className='nikhil'>
           <div class="frame26">
        <button class="nextButton" style={{border:'none',background:'none'}}>Next</button>
        <div class="">
        <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15 7L1 7M15 7L9 13M15 7L9 1" stroke="#1A1A1A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

        </div>
    </div>
           </div>

    {/* <!-- Thank you message --> */}
    <div class="thankYouMessage">
        Thank you for the order, we have emailed your order confirmation to adam@abcd.com
    </div>

       </div>

      </div>
    );
  }
}

export default MainContent;
