import React, { useEffect, useState } from 'react';
import { FaExclamationCircle, FaTimes } from 'react-icons/fa';

const Notification = ({ message, onClose }) => {
    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
        // Set up the timer to start fading out the notification after 5 seconds
        const timer = setTimeout(() => {
            setIsVisible(false);
        }, 10000);

        // Call onClose after the fade-out animation completes
        const fadeOutTimer = setTimeout(() => {
            onClose();
        }, 5500); // 500ms longer to account for fade-out duration

        // Clean up the timers if the component is unmounted before they complete
        return () => {
            clearTimeout(timer);
            clearTimeout(fadeOutTimer);
        };
    }, [onClose]);

    return (
        <div className={`notification ${isVisible ? 'fade-in' : 'fade-out'}`}>
            <FaExclamationCircle className="notification-icon" />
            <span className="notification-text">{message}</span>
            <button className="notification-close" onClick={() => {
                setIsVisible(false);
                onClose();
            }}>
                <FaTimes />
            </button>
        </div>
    );
};

export default Notification;
