import React, { useState, useEffect } from 'react';
import '../AddLicences/AddLicenses.css'
import buynow from '../Assets/buynow.svg';
import { useNavigate } from 'react-router-dom';
import Notification from '../../components/Notification';
import filledDropdownicon from '../Assets/filledDropdownicon.svg';
import axios from 'axios';


const AddSubscriptionForm = ({ onAction, subscriptionId }) => {
    const [count, setCount] = useState(1);
    const [subscriptionLength, setSubscriptionLength] = useState('oneMonth');
    const [billingTerm, setBillingTerm] = useState('');
    const [actualBillingTerm, setActualBillingTerm] = useState('');
    const [customerAddress, setCustomerAddress] = useState({});
    const [costPerUser, setcostPerUser] = useState('');
    const [existingLicenses, setexistingLicenses] = useState('');
    const [subConsole, setSubConsole] = useState('');
    const [itemName, setitemName] = useState('');
    const totalLicenses = existingLicenses + count;
    const totalAmount = (count * costPerUser).toFixed(2);
    const [loading, setLoading] = useState(true);
    const [currency, setCurrency] = useState('')
    const [message, setMessage] = useState('');



    useEffect(() => {
        axios.get(`/add/user/${subscriptionId}`)
            .then(response => {
                const data = response.data;
                console.log(data, 'buy_more_data.......')
                setcostPerUser(data.cost_per_person);
                setexistingLicenses(data.quantity);
                setitemName(data.item_name);
                setCustomerAddress(data.customer_address)
                setSubConsole(data.console)

                if (data.billing_cycle === 'M') {
                    setBillingTerm('Monthly');
                } else {
                    setBillingTerm('Annual');
                }
                if (data.actual_billing_cycle === 'M') {
                    setActualBillingTerm('Monthly');
                } else {

                    setActualBillingTerm('Annual');
                }
                if (data.currency === "INR"){
                    setCurrency("₹")
                } else {
                    setCurrency("$")
                }
                setLoading(false)
            })
            .catch(error => {
                console.error("There was an error fetching the subscriptions!", error);
                setLoading(false)
            });
    }, []);

    const increaseCount = () => {
        setCount(count + 1);
    };

    const decreaseCount = () => {
        if (count > 1) {
            setCount(count - 1);
        }
    };

    const handleSubscriptionChange = (e) => {
        setSubscriptionLength(e.target.id);
    };

    const toggleBillingTerm = () => {
        if (billingTerm === 'M') {
            // Set to Monthly
            setBillingTerm('Monthly');
            setActualBillingTerm('Monthly');
        } else {
            // Set to Yearly
            setBillingTerm('Y');
            setActualBillingTerm('Yearly');
        }
    };

    const handleNotificationClose = () => {
        setMessage('');
    };


    const getSubscriptionPrice = () => {
        return subscriptionLength === 'oneMonth' ? 450 * count : 4200 * count;
    };

    const calculateTotalCost = () => {
        const taxRate = 0; // Example tax rate
        const subtotal = getSubscriptionPrice();
        const taxAmount = subtotal * taxRate;
        return subtotal + taxAmount;
    };

    const navigate = useNavigate();

    const handlenextpage = () => {
        navigate('/stage2');
        window.scrollTo(0, 0);
    };
    const handleNext = () => {
        if(count === '' || count <= 0){
            setMessage("Quantity required and it should be greater than 0");
            return false
        }
        onAction({
            subscriptionId,
            itemName,
            costPerUser,
            count,
            totalAmount,
            billingTerm,
            actualBillingTerm,
            customerAddress,
            subConsole,
            currency
        });
    };

    return (
        <div className='AddSubForm-S1subscription-form'>
            {loading && (
                <div className="modal-overlay">
                    <div className="preloader-modal">
                        <div className="spinner"></div>
                    </div>
                </div>
            )}
            <div className='AddSubForm-LPServicesHeading'>
                <span>Add More Licenses</span>
                <p className='licenses-description'>Choose the number of users, subscription length and billing term you prefer.</p>
            </div>

            <div className='AddSubForm-S1fields'>
                <div className='AddSubForm-S1frame21'>
                    <div className='AddSubForm-S1frame13'>
                        <label>Quantity *</label>
                        <div className='AddSubForm-S1counter-container'>
                            <input
                                type="number"
                                className='AddSubForm-S1count-display'
                                placeholder='Quantity'
                                value={count}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    setCount(value === "" ? "" : parseInt(value));
                                }}
                            />
                        </div>
                    </div>
                </div>

                {/* Updated UI */}
                <div className="AddSubForm-S1existing-licenses">
                    <span>Existing Licenses: </span>
                    <span className="AddSubForm-S1existing-licenses-count">{existingLicenses}</span>
                </div>

                <div className="AddSubForm-S1noof-licenses">
                    <span>Total No. of Licenses:</span>
                    <div className='AddSubForm-S1noof-licenses-count'>
                        <span>{existingLicenses} + {count}</span>
                        <img src={buynow} alt="Buy Now" />
                        <span>{totalLicenses}</span>
                    </div>
                </div>

                <div className='AddSubForm-S1frame23'>
                    <span>Your Order Summary:</span>
                    <div className='AddSubForm-S1frame22'>
                        <p>Add on Licenses - {itemName}</p>
                        <div>Pay {currency}{totalAmount} for {count} user{count > 1 ? 's' : ''} ({currency}{costPerUser}/user)</div>
                    </div>
                </div>

                <div className='AddSubForm-S1frame27'>
                    <div className='AddSubForm-S1frame25'>
                        <span>Payment Due Today (tax not included):</span>
                        <div className='S1amount add_sub'>{currency}{totalAmount}</div>
                    </div>
                    <div className='AddSubForm-S1frame26' onClick={handleNext}>
                        <span>Next</span>
                        <img src={buynow} alt="Buy Now" />
                    </div>
                </div>
                {message && (
                    <div className="error-msg-notification-container">
                        <Notification key={Date.now()} message={message} onClose={handleNotificationClose} />
                    </div>
                )}
            </div>
        </div>

    );
};
export default AddSubscriptionForm;
