import React, { useState, useEffect } from "react";
import SubscriptionForm from "./SubscriptionDetails";
import ReviewPlaceOrder from "./ReviewPlaceOrder";
import Navbar from "../Dashboard/Navbar";
import './purchaceFlow.css';
import troubleicon from '../Assets/troubleicon.svg';
import contactSupport from "../Assets/contactSupport.svg"
import MicrosoftSignIn from "./MicrosoftSignin";
import WelcomeOnboard from "./Closure";
import CreateAccount from "./CreateAccount";
import "../AddLicences/AddLicenses.css";
import { useParams, useLocation } from 'react-router-dom';

const PurchaseFlow = () => {
    const [stage, setStage] = useState(null); // Initialize stage as null
    const [formData, setFormData] = useState({}); // State to hold the data passed between stages
    const [loading, setLoading] = useState(true);
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const location = useLocation();
    const { country } = location.state || {}; // Get the country from location state
    const { id } = useParams();

    const handleStageChange = (data) => {
        setFormData({
            ...formData,
            ...data,
            country, // Always include country in the formData
        });
        setStage(stage + 1);
        window.scrollTo(0, 0);
    };

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    useEffect(() => {
        const fetchOrderDetails = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await fetch(`/get/stage?id=${id}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `${token}`,
                    },
                });
                const data = await response.json();

                // Determine the next stage based on the response
                const stageMap = {
                    "stage1": 2,
                    "stage2": 3,
                    "stage3": 4,
                    "stage4": 5
                };

                const nextStage = stageMap[data.stage];
                if (nextStage) {
                    setStage(nextStage); // Move to the appropriate next stage
                    setLoading(false);
                }
            } catch (error) {
                console.error('Error fetching order details:', error);
                setLoading(false);
            }
        };

        fetchOrderDetails();
    }, [id]);

    const handleSetStage = (n) => {
        setStage(n);
        window.scrollTo(0, 0);
    };

    const steps = [
        { title: "Subscription Details", subtitle: "Set up your Subscription" },
        { title: "Create Account", subtitle: "Enter your email and details" },
        { title: "Place Order", subtitle: "Review and Place Order" },
        { title: "Microsoft Sign-In", subtitle: "Sign-in to Microsoft 365" },
        { title: "Order Processed", subtitle: "Fulfilment Pending" }
    ];

    const stages = {
        1: <SubscriptionForm onAction={handleStageChange} />,
        2: <CreateAccount onAction={handleStageChange} countryData={formData} />,
        3: <ReviewPlaceOrder onAction={handleStageChange} countryData={formData} />,
        4: <MicrosoftSignIn onAction={handleStageChange} countryData={formData} />,
        5: <WelcomeOnboard countryData={formData} />
    };

    if (stage === null) {
        return (
            <div className="modal-overlay">
                <div className="preloader-modal">
                    <div className="spinner"></div>
                </div>
            </div>
        );
    }
    return (
        <div className="landing-page">
            <Navbar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
            {loading && (
                <div className="modal-overlay">
                    <div className="preloader-modal">
                        <div className="spinner"></div>
                    </div>
                </div>
            )}
            <div className="container-fluid maincontent">
                <div className="row">
                <div className={`col-3 S1col-4 sidebar ${sidebarOpen ? "open" : ""}`}>
                        <div className="LPnav-panel">
                            <div className="LPnav-options">
                                {steps.map((step, index) => (
                                    <React.Fragment key={index}>
                                        <div className="LPstep">
                                            <div
                                                className={`LPcircle ${index + 1 <= stage ? 'active' : ''}`}
                                                // onClick={() => handleSetStage(index + 1)}
                                            >
                                                <span className={`${index + 1 <= stage ? 'active' : ''}`}>{index + 1}</span>
                                            </div>
                                            <div className="LPstep-details">
                                                <div className={`LPtitle ${index + 1 <= stage ? 'active' : ''}`}>{step.title}</div>
                                                <div className={`LPsubtitle ${index + 1 <= stage ? 'active' : ''}`}>{step.subtitle}</div>
                                            </div>
                                        </div>
                                        {index < steps.length - 1 && <div className="LPvertical-dashed-line"></div>}
                                    </React.Fragment>
                                ))}
                            </div>

                            <div className="LPhelp-section">
                                <img src={troubleicon} alt="Trouble Icon" />
                                <div className="LPhelp-details">
                                    <div className="LPhelp-title">Having trouble?</div>
                                    <div className="LPhelp-description">Feel free to contact us and we will always help you through the process.</div>
                                </div>
                                <a href="https://www.briotech.com/contact-us/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
                                    <div className="LPcontact-button">
                                    <img src={contactSupport} alt="Contact Icon" />
                                       Contact Us
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className={`col-9 S1col-8  ${sidebarOpen ? "hidden-content" : ""}`}>
                        {stages[stage] || null}  {/* Render the stage component or nothing */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PurchaseFlow;
