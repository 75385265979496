import React from 'react';
import { Link } from 'react-router-dom';
// import '../PurchaceFlow/WelcomeOnboard.css';
import '../RenewSubscription/RenewSubscription.css'
import checkmarkicon from '../Assets/checkmarkicon.svg';
import buynow from '../Assets/buynow.svg';

const RenewSubscriptionWelcomeOnboard = () => {

  return (
    // <div className="welcomeContainer">
    //   <div className="welcomeHeader">
    //     <div className="checkmarkIcon" style={{ backgroundImage: `url(${checkmarkicon})` }} ></div>
    //     <h2 className="welcomeTitle">Welcome Onboard!</h2>
    //   </div>
      
      
    //   <div className="welcomeFooter">
     
    //     <p>Your Order is Processed. Invoice will be sent to your registered email ID. Sit back and relax, we are working on your order fulfillment. You will be notified once it is fulfilled. Meanwhile, you can login or explore other services.</p>
    //     <div className="buttonsContainer">
    //     <Link to='/Home' className="exploreButton" style={{textDecoration:'none'}}>
    //         <div >Explore Services</div>
    //     </Link>
    //       <Link to='/login' >
    //        <div className="loginButton">
    //          <span>Login</span>
    //          <img src={buynow} />
    //        </div>
    //       </Link>
    //     </div>
    //   </div>
    // </div>
    <div className="RenewSubCl-welcomeContainer">
    <div className="RenewSubCl-welcomeHeader">
        <div className="RenewSubCl-checkmarkIcon" style={{ backgroundImage: `url(${checkmarkicon})` }}></div>
        <h2 className="RenewSubCl-welcomeTitle">License Renewed</h2>
    </div>

    <div className="RenewSubCl-welcomeFooter">
    <p>
        Your order has been fulfilled, and we've sent your Microsoft login credentials and subscription details to your registered email. You're all set to access the services right away. In the meantime, feel free to <a href='https://go.microsoft.com/fwlink/?linkid=2081615&clcid=0x409&culture=en-us&country=us' target="_blank" rel="noopener noreferrer">login</a> or explore more of what we offer!
      </p>
        <div className="RenewSubCl-buttonsContainer">
        <a href='/Home' className="exploreButton" style={{textDecoration: 'underline'}} target="_blank" rel="noopener noreferrer">
          <div>Explore Services</div>
        </a>
        <a href='/subscriptions' className="exploreButton" style={{textDecoration: 'none', border:'1px solid #000'}} target="_blank" rel="noopener noreferrer">
            <div>Manage Subscriptions</div>
        </a>
        </div>
    </div>
</div>

  );
};

export default RenewSubscriptionWelcomeOnboard;


