import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Navbar from '../Dashboard/Navbar.jsx';
import MyProfileNavbar from './MyProfileNavbar.jsx';
import msicon from '../Assets/msicon.svg';
import msicondim from '../Assets/msicondim.svg';
import addicon from '../Assets/addicon.svg';
import whiteAddicon from '../Assets/whiteAddicon.svg';
import refreshicon from '../Assets/refreshicon.svg';
import disablerefreshicon from '../Assets/disablerefreshicon.svg'
import disableaddicon from '../Assets/disableaddicon.svg'
import buynow from '../Assets/buynow.svg';
import axios from 'axios';


const Subscriptions = () => {
    const [activeSubscriptions, setActiveSubscriptions] = useState([]);
    const [expiredSubscriptions, setExpiredSubscriptions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [subData, setSubData] = useState();
    const [clickType, setClickType] = useState(null);
    const [dataFetched, setDataFetched] = useState(false);
    const [pcView, setPcView] = useState(true)
    const navigate = useNavigate();

    useEffect(() => {
        const updateServicesPerPage = () => {
            if (window.matchMedia('(max-width: 400px)').matches) {
                setPcView(false);
            } else {
                setPcView(true);
            }
        };

        updateServicesPerPage(); // Set initially
        window.addEventListener('resize', updateServicesPerPage); // Listen for resize events

        return () => window.removeEventListener('resize', updateServicesPerPage); // Clean up listener
    }, []);

    useEffect(() => {
        const customerId = localStorage.getItem('customerId');
        axios.get(`/api/subscriptions?customerId=${customerId}`)
            .then(response => {
                console.log(response, 'response')
                const data = response.data
                console.log(data, 'sub_data')
                setActiveSubscriptions(data.active_subscriptions);
                setExpiredSubscriptions(data.expired_subscriptions);
                setDataFetched(true)
                setLoading(false)
                console.log(data.active_subscriptions.length, 'llll');
                console.log(data.expired_subscriptions.length, 'mmmmm');

            })
            .catch(error => {
                console.error("There was an error fetching the subscriptions!", error);
                setLoading(false)
            });
    }, []);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate();
        const month = date.toLocaleString('default', { month: 'long' });
        const year = date.getFullYear();
        const ordinalSuffix = (day) => {
            if (day > 3 && day < 21) return 'th';
            switch (day % 10) {
                case 1: return 'st';
                case 2: return 'nd';
                case 3: return 'rd';
                default: return 'th';
            }
        };

        const suffix = ordinalSuffix(day);
        return (
            <>
                {day}
                <sup>{suffix}</sup> {month} {year}
            </>
        );
    };



    const getDateDifferenceInDays = (endDate) => {
        const start = new Date(); // Current date
        const end = new Date(endDate);
        const differenceInTime = end - start;
        return Math.ceil(differenceInTime / (1000 * 60 * 60 * 24));
    };

    const handleBuyMoreClick = async (subscriptionId) => {
        console.log('handleBuyMoreClick_function');

        try {
            setLoading(true);
            setClickType('add');
            const response = await axios.get(`/api/subscription/${subscriptionId}`);
            const sub_data = response.data;
            setSubData(sub_data);
            console.log(sub_data);
            console.log(sub_data.add_payment_status, 'status');
            if (sub_data.add_payment_status === 'completed') {
                setLoading(false);
                setIsPopupOpen(true);
            } else {
                setLoading(false);
                navigate(`/buy/more/${subscriptionId}`);
                // /:id/renew
            }
        } catch (error) {
            console.error("Error fetching subscription data!", error);
            setLoading(false);
        }
    };

    const handleRenewClick = async (subscriptionId) => {
        console.log('handleRenewClick_function');

        try {
            setLoading(true);
            setClickType('renew');
            const response = await axios.get(`/api/subscription/${subscriptionId}`);
            const sub_data = response.data;
            setSubData(sub_data);
            console.log(sub_data);
            console.log(sub_data.renew_payment_status, 'status');
            if (sub_data.renew_payment_status === 'completed') {
                setLoading(false);
                setIsPopupOpen(true);
            } else {
                setLoading(false);
                navigate(`/${subscriptionId}/renew`);
            }
        } catch (error) {
            console.error("Error fetching subscription data!", error);
            setLoading(false);
        }
    };

    const handleContinueClick = async () => {
        closeModal();
        if (subData.add_payment_details) {
            const captureDetails = {
                payment_details: subData.add_payment_details,
                subscription_id: subData.id
            };
            const url = `/add/user/${subData.id}`;

            try {
                setLoading(true);
                const result = await axios.post(url, captureDetails);
                if (result.data.status) {
                    navigate(`/buy/more/${subData.id}?stage=3`);
                    console.log("Payment capture successful.");
                }
                setLoading(false);
            } catch (error) {
                console.error('Error capturing payment details:', error);
                setLoading(false);
            }
        } else {
            console.error("Error: payment_details is missing in sub_data");
        }


    };

    const handleRenewContinueClick = async () => {
        closeModal();
        if (subData.renew_payment_details) {
            const captureDetails = {
                payment_details: subData.renew_payment_details,
                subscription_id: subData.id
            };
            const url = `/renew-subscription/${subData.id}`;

            try {
                setLoading(true);
                const result = await axios.post(url, captureDetails);
                if (result.data.status) {
                    navigate(`/${subData.id}/renew?stage=3`);
                    console.log("Payment capture successful.");
                }
                setLoading(false);
            } catch (error) {
                console.error('Error capturing payment details:', error);
                setLoading(false);
            }
        } else {
            console.error("Error: payment_details is missing in sub_data");
        }


    };

    const handleTUP = () => {
        navigate('/Home?TUP=true')
    }

    const closeModal = () => {
        setIsPopupOpen(false);
        setClickType(null);
    };

    return (
        <div className="landing-page">
            <Navbar />
            {loading && (
                <div className="modal-overlay">
                    <div className="preloader-modal">
                        <div className="spinner"></div>
                    </div>
                </div>
            )}
            <div className="ms-subscriptions-container">
                <div className='ms-title-adjust'>
                    <div className='ms-title-box'>
                        <h1 className="ms-title">My Subscriptions</h1>
                    </div>
                </div>
                <div className="ms-subscriptions-box">
                    {/* Active Subscriptions */}
                    <div className="ms-section">
                        <div style={{ display: 'flex', justifyContent: activeSubscriptions.length === 0 ? 'flex-start' : 'space-between' }}>
                            <h2 className="ms-section-title">Active Subscriptions</h2>
                            {activeSubscriptions && activeSubscriptions.length > 0 ? (
                            <button className='TUP-button' onClick={handleTUP}>
                            <img
                                src={whiteAddicon}
                                alt="buy-more"
                                style={{width:'30px' }}
                            /> {pcView && (<>New Subscription</>)}
                            </button>
                            ):(<></>)}
                        </div>
                        <div className='mySub'  style={{width:'100%'}}>
                        <div style={{minWidth:'1150px'}}>
                        <div className="ms-header">
                            <div className="ms-header-item-service">Service Details</div>
                            <div className="ms-header-item">Domain</div>
                            <div className="ms-header-item">Purchased Licenses</div>
                            <div className="ms-header-item">Start Date</div>
                            <div className="ms-header-item">End Date</div>
                            <div className="ms-header-item">Actions</div>
                        </div>
                        {activeSubscriptions && activeSubscriptions.length > 0 ? (
                            activeSubscriptions.map(subscription => {
                                const daysUntilEnd = getDateDifferenceInDays(subscription.end_date);
                                return (
                                    <div key={subscription.id} className="ms-subscription-item">
                                        <div className="ms-service-details">
                                            <img src={msicon} alt="service-icon" className="ms-service-icon" />
                                            <div>
                                                <p className="ms-service-name">{subscription.item_name}</p>
                                                <p className="ms-service-plan">{subscription.plan}</p>
                                            </div>
                                        </div>
                                        <div className="ms-license-count">{subscription.domain}</div>
                                        <div className="ms-license-count"><span style={{ marginRight: '4px' }}>x</span>{subscription.quantity}</div>
                                        <div className="ms-expiring-on">{formatDate(subscription.start_date)}</div>
                                        <div className="ms-billing-date">{formatDate(subscription.end_date)}</div>
                                        <div className="ms-actions">
                                            <img
                                                src={addicon}
                                                alt="buy-more"
                                                onClick={() => handleBuyMoreClick(subscription.id)}
                                                style={{ cursor: 'pointer' }}
                                            />
                                            {daysUntilEnd > 30 ? (
                                                <div>
                                                    <img src={disablerefreshicon} alt="renew-subscription" />
                                                </div>
                                            ) : (
                                                <img
                                                    src={refreshicon}
                                                    alt="renew-subscription"
                                                    onClick={() => handleRenewClick(subscription.id)}
                                                    style={{ cursor: 'pointer' }}
                                                />
                                            )}
                                        </div>
                                    </div>
                                );
                            })
                        ) : (
                            dataFetched ? (<div className="no-subscriptions">
                                <h5>No active subscriptions found.</h5>
                            </div>) : null
                        )}
                        </div>
                        </div>
                    </div>

                    {/* Expired Subscriptions */}
                    <div className="ms-section">
                        <h2 className="ms-section-title">Expired Subscriptions</h2>
                        <div className='mySub' style={{width:'100%'}}>
                        <div style={{minWidth:'1150px'}}>
                        <div className="ms-header">
                            <div className="ms-header-item-service">Service Details</div>
                            <div className="ms-header-item">Purchased Licenses</div>
                            <div className="ms-header-item">Start Date</div>
                            <div className="ms-header-item">End Date</div>
                            <div className="ms-header-item">Actions</div>
                        </div>
                        {expiredSubscriptions && expiredSubscriptions.length > 0 ? (
                            expiredSubscriptions.map(subscription => (
                                <div key={subscription.id} className="ms-subscription-item expired">
                                    <div className="ms-service-details">
                                        <img src={msicondim} alt="service-icon" className="ms-service-icon" />
                                        <div>
                                            <p className="ms-service-name">{subscription.item_name}</p>
                                            <p className="ms-service-plan">{subscription.plan}</p>
                                        </div>
                                    </div>
                                    <div className="ms-license-count">
                                        <span style={{ marginRight: '4px' }}>x</span>{subscription.quantity}
                                    </div>
                                    <div className="ms-expiring-on">{formatDate(subscription.start_date)}</div>
                                    <div className="ms-billing-date">{formatDate(subscription.end_date)}</div>
                                    <div className="ms-actions">
                                        <div>
                                            <img src={disableaddicon} alt="renew-subscription" />
                                        </div>
                                        <Link to={`/${subscription.id}/renew`}>
                                            <img src={refreshicon} alt="renew-subscription" />
                                        </Link>
                                    </div>
                                </div>
                            ))
                        ) : (
                            dataFetched ? (<div className="no-subscriptions">
                                <h5>No expired subscriptions found.</h5>
                            </div>) : null

                        )}
                        </div>
                        </div>
                    </div>
                </div>

                <div className="ms-explore-services">
                    <p>Add new services to your account</p>
                    <Link to="/">
                        <button className="ms-explore-button">Explore Services <img src={buynow} alt="explore-services" /></button>
                    </Link>
                </div>
            </div>
            {isPopupOpen && (
                <div className="order-modal-overlay" onClick={closeModal}>
                    <div
                        className="modal-content"
                        style={{ width: '95%' }}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div className="order-popup">
                            <div className="order-popup-header">
                                <svg
                                    onClick={closeModal}
                                    style={{ cursor: "pointer" }}
                                    width="18"
                                    height="18"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M15.669 5.7858C15.7377 5.71304 15.7915 5.62746 15.8271 5.53393C15.8628 5.4404 15.8797 5.34076 15.8769 5.2407C15.8741 5.14064 15.8515 5.04212 15.8106 4.95076C15.7697 4.8594 15.7112 4.77699 15.6385 4.70823C15.5657 4.63948 15.4801 4.58573 15.3866 4.55006C15.2931 4.51438 15.1934 4.49747 15.0934 4.5003C14.9933 4.50314 14.8948 4.52565 14.8034 4.56655C14.7121 4.60746 14.6297 4.66596 14.5609 4.73872L8.08334 11.5973C7.94957 11.7388 7.87503 11.9262 7.87503 12.1209C7.87503 12.3156 7.94957 12.5029 8.08334 12.6444L14.5609 19.5038C14.6292 19.5781 14.7116 19.6382 14.8033 19.6804C14.895 19.7226 14.9942 19.7462 15.0951 19.7498C15.196 19.7534 15.2966 19.7369 15.391 19.7013C15.4855 19.6657 15.5719 19.6116 15.6453 19.5423C15.7187 19.473 15.7776 19.3898 15.8186 19.2975C15.8596 19.2053 15.8818 19.1058 15.884 19.0048C15.8862 18.9039 15.8683 18.8035 15.8314 18.7096C15.7945 18.6156 15.7393 18.5299 15.669 18.4575L9.6852 12.1209L15.669 5.7858Z"
                                        fill="#1A1A1A"
                                    />
                                </svg>

                                <img
                                    src={msicon}
                                    alt="service-icon"
                                    className="order-service-icon1"
                                />
                                <span style={{ fontSize: "16px" }}>
                                    {subData.item_name}
                                </span>
                                {clickType === 'renew' ? (
                                    <span>x {subData.renew_payment_details.quantity}</span>
                                ) : (
                                    <span>x {subData.add_payment_details.quantity}</span>
                                )}
                            </div>
                            <div className="order-popup-body">
                                <div className="order-popup-section">
                                    <p className="p-details">Price Details:</p>
                                    <p>
                                        Total Amount (1 item):{" "}
                                        <span style={{ fontWeight: "bold" }}>₹ {clickType === 'renew' ? subData.renew_payment_details.amount : subData.add_payment_details.amount}</span>
                                    </p>
                                    <p>
                                        Tax Amount:{" "}
                                        <span style={{ fontWeight: "bold" }}>
                                            ₹{clickType === 'renew' ? subData.renew_payment_details.tax_amount : subData.add_payment_details.tax_amount}
                                        </span>{" "}
                                    </p>
                                </div>
                                <div className="order-popup-section">
                                    <p className="p-details">Sold to Address:</p>
                                    <p>
                                        {clickType === 'renew' ?
                                            subData.renew_shipping_address[0]["add1"] + "," + subData.renew_shipping_address[0]["add2"] + "," + subData.renew_shipping_address[0]["city"] + "," + subData.renew_shipping_address[0]["state"] + "," + subData.renew_shipping_address[0]["country"] + "," + subData.renew_shipping_address[0]["postal_code"] :
                                            subData.add_shipping_address[0]["add1"] + "," + subData.add_shipping_address[0]["add2"] + "," + subData.add_shipping_address[0]["city"] + "," + subData.add_shipping_address[0]["state"] + "," + subData.add_shipping_address[0]["country"] + "," + subData.add_shipping_address[0]["postal_code"]
                                        }
                                    </p>
                                </div>
                            </div>
                            <div className='d-flex justify-content-between align-items-center'>
                                <p>Payment is completed. Click on Continue to proceed with your subscription purchase</p>
                                {clickType === 'renew'
                                    ? <button className="order-continue-button" onClick={handleRenewContinueClick}>Continue <img src={buynow} alt="continue" /></button>
                                    : <button className="order-continue-button" onClick={handleContinueClick}>Continue <img src={buynow} alt="continue" /></button>
                                }
                            </div>
                            <div className="order-popup-footer mt-3">
                                <p>Grand Total: </p>
                                <span>₹{clickType === 'renew'
                                    ? subData.renew_payment_details.total_amount
                                    : subData.add_payment_details.total_amount}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>

    );

};

export default Subscriptions;
