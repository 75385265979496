import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import './WelcomeOnboard.css';
import checkmarkicon from '../Assets/checkmarkicon.svg';
import copyicon from '../Assets/copyicon.svg';
import buynow from '../Assets/buynow.svg';
import tikMark from '../Assets/tikMark.svg'

const WelcomeOnboard = (countryData) => {
  const [copied, setCopied] = useState(false);
  const { id } = useParams();
  const [orderData, setOrderData] = useState({"username":"", "password":""});
  const [country, setCountry] = useState(countryData?.country || '');
  const [loading, setLoading] = useState(true); // Loading state
  const currency = country === "IN" ? "INR" : country === "US" ? "USD" : null;
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  useEffect(() => {
    const fetchOrderDetails = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await fetch(`/closure?id=${id}`, {
              method: 'GET',
              headers: {
                  'Authorization': `${token}`,
              },
          });
            const data = await response.json();
            console.log(data)
            setCountry(data.country);
            setOrderData(data);
            setLoading(false); // Data is loaded, hide the loader
        } catch (error) {
            console.error('Error fetching order details:', error);
            setLoading(false);
        }
    } 
    fetchOrderDetails();
  }, [id]);

  if (loading) {
    return <div className="loader"><div className="modal-overlay">
    <div className="preloader-modal">
      <div className="spinner"></div>
    </div>
  </div></div>; // Show loader while data is being fetched
  }
  return (
    <div className="welcomeContainer">
      <div className="welcomeHeader">
        <div className="checkmarkIcon" style={{ backgroundImage: `url(${checkmarkicon})` }} ></div>
        <h2 className="welcomeTitle">
          {orderData.is_existing_customer === "true" ? (<>Get started with your Microsoft 365 subscription</>):(<>Welcome Onboard!</>)}
          </h2>
      </div>
      {orderData.is_existing_customer === "true" ? (<></>):(
      <div className="credentialsContainer">
        <p className="credentialsTitle">Your Login Credentials</p>
        <div className="credentialItem">
          <span className="credentialLabel">Username:</span>
          <span className="credentialValue">{orderData.username}</span>
          <button 
            className="copyIcon" 
            onClick={() => copyToClipboard(orderData.username)}
          ><img src={copyicon} /></button>
        </div>
        <div className="credentialItem">
          <span className="credentialLabel">Password:</span>
          <span className="credentialValue">{orderData.password}</span>
          <button 
            className="copyIcon" 
            onClick={() => copyToClipboard(orderData.password)}
          ><img src={copyicon} /></button>
        </div>
        {copied && <p className="copyMessage">Copied!</p>}
      </div>
      )}
      {orderData.is_existing_customer === "true" ? (
          <>
            <div className={`message-box success`}>
              <div className="message-icon">
                <img src={tikMark} alt={`success icon`} className={`icon-success`} />
              </div>
              <p>Thank you for accepting the partner agreement. We've added the new subscription to your existing account: {orderData.existing_customer_email}.</p>
            </div>
          </>
          ):(<></>)}
      <div className="welcomeFooter">
        {orderData.is_existing_customer === "true" ? (
          <div>
            <p>You can get started using your subscription by logging into the Microsoft 365 Admin Center so you can:</p>
            <ul>
              <li> Install Word, Excel, PowerPoint, and more.</li>
              <li> Invite your team to use Microsoft 365.</li>
              <li>Add or connect your domain to build credibility and recognition for your brand.</li>
            </ul>
            <p>You can also manage your subscription through your Brio Storefront account.</p>
          </div>
        ) : (
          <p>
            Your order has been fulfilled, and we've sent your Microsoft login credentials and subscription details to your registered email. You're all set to access the services right away. In the meantime, feel free to <a href='https://go.microsoft.com/fwlink/?linkid=2081615&clcid=0x409&culture=en-us&country=us' target="_blank" rel="noopener noreferrer">login</a> or explore more of what we offer!
          </p>
        )}
        <div className="buttonsContainer">
          <a href='/Home' className="exploreButton" style={{textDecoration: 'underline'}} target="_blank" rel="noopener noreferrer">
            <div>Explore Services</div>
          </a>
          <a href='/subscriptions' className="exploreButton" style={{textDecoration: 'none', border:'1px solid #000'}} target="_blank" rel="noopener noreferrer">
            <div>Manage Subscriptions</div>
          </a>
          <a href='https://go.microsoft.com/fwlink/?linkid=2081615&clcid=0x409&culture=en-us&country=us' style={{textDecoration:'none'}} target="_blank" rel="noopener noreferrer">
            <div className="S1frame26">
              <span>Get started</span>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default WelcomeOnboard;
