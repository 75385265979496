import React, { useState, useEffect } from 'react';
import buynow from '../Assets/buynow.svg';
import { useNavigate, useParams } from 'react-router-dom';
import '../RenewSubscription/RenewSubscription.css'
import axios from 'axios'

const RenewSubscriptionForm = ({ onAction }) => {
    const { id } = useParams();
    const [count, setCount] = useState(0);
    const [unitPrice, setUnitPrice] = useState(null);
    const [billingCycle, setBillingCycle] = useState(null);
    const [actuallBillingCycle, setActuallBillingCycle] = useState(null);
    const [itemName, setItemName] = useState(null);
    const [customerAddress, setCustomerAddress] = useState(null);
    const [subscriptionLength, setSubscriptionLength] = useState('oneMonth');
    const [billingTerm, setBillingTerm] = useState('Monthly');
    const [orderDetails, setOrderDetails] = useState(null);
    const [subDetailsDict, setSubDetailsDict] = useState(null);
    const [currencyIcon, setCurrencyIcon] = useState("")
    const [currency, setCurrency] = useState("INR")
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        axios.get(`/renew-subscription/${id}`)
            .then(response => {
                console.log(response, 'renew response data.....')
                const sub_deatils = response.data["subscription_data"]
                console.log(sub_deatils.quantity, 'data.....quantity')
                setCount(sub_deatils.quantity);
                const unitprice = sub_deatils.unit_price;
                setBillingCycle(sub_deatils.billing_cycle);
                setActuallBillingCycle(sub_deatils.actual_billing_cycle);
                setItemName(sub_deatils.item_name)
                setCustomerAddress(sub_deatils.customer_address)
                setSubDetailsDict(sub_deatils)
                setCurrency(sub_deatils.currency)
                setUnitPrice(unitprice)
                if (sub_deatils.currency === "USD"){
                    setCurrencyIcon("$")
                } else {
                    setCurrencyIcon("₹")
                }
                const data = sub_deatils
                setLoading(false)
            })
            .catch(error => {
                console.error("There was an error fetching the renew subscriptions", error);
                setLoading(false)
            });
    }, [id]);


    const increaseCount = () => {
        setCount(count + 1);
    };

    const decreaseCount = () => {
        if (count > 1) {
            setCount(count - 1);
        }
    };

    const handleSubscriptionChange = (e) => {
        setSubscriptionLength(e.target.id);
    };

    const toggleBillingTerm = () => {
        setBillingTerm(billingTerm === 'Monthly' ? 'Yearly' : 'Monthly');
    };

    const getSubscriptionPrice = () => {
        console.log("saad ", currency, currencyIcon, unitPrice)
        return unitPrice * count;
    };

    const calculateTotalCost = () => {
        const subtotal = getSubscriptionPrice();
        return subtotal;
    };

    const navigate = useNavigate();

    // const handlenextpage = () => {
    //     navigate('/AddSubscriptionStage2');
    //     window.scrollTo(0, 0);
    // };

    const handleNext = () => {
        const dueAmount = calculateTotalCost();
        onAction({
            count,
            subDetailsDict,
            dueAmount,
        });
    };

    const handleInputChange = (event) => {
        const inputValue = event.target.value;
        if (/^\d*$/.test(inputValue)) {
            setCount(inputValue);
        }
    };
    

    const validateCount = () => {
        const numericValue = parseFloat(count);
        if (isNaN(numericValue) || numericValue < 0) {
            setCount(0);
        }
    };
   
    return (
        <div>
            {loading && (
                <div className="modal-overlay">
                    <div className="preloader-modal">
                        <div className="spinner"></div>
                    </div>
                </div>
            )}
            <div className='RenewSubForm-S1subscription-form'>
                <div className='RenewSubForm-S1form-heading'>
                    <div className='RenewSubForm-S1title'>Renew Your Subscription</div>
                    <p>Choose the number of users you prefer for renew.</p>
                </div>

                <div className='S1fields'>
                    <div className='S1frame21'>
                        <div className='S1frame13'>
                            <label>How many people is this for?</label>
                            <div>
                                <input
                                    type='text'
                                    className='count-input-box'
                                    value={count}
                                    onChange={handleInputChange}
                                    onBlur={validateCount}
                                />
                            </div>
                        </div>
                    </div>

                    <div className='mt-3'>
                        <label>Current Commitment Plan</label>
                        <p style={{ fontWeight: 'bold', fontSize: '14px' }}>{billingCycle === 'M' ? 'Monthly' : 'Annually'}</p>
                    </div>

                    <div className='mt-2'>
                        <label>Current Billing Plan</label>
                        <p style={{ fontWeight: 'bold', fontSize: '14px' }}>{actuallBillingCycle === 'M' ? 'Monthly' : 'Annually'}</p>
                    </div>

                    <div >
                        <span>Your Order Summary:</span>
                        <div className='S1frame22'>
                            <div>{itemName}</div>
                            <div>Pay {currencyIcon}{getSubscriptionPrice()} {billingCycle === 'M' ? 'Monthly' : 'Annually'} for {count} user{count > 1 ? 's' : ''}</div>
                        </div>
                    </div>
                    <div className='S1frame27'>
                        <div className='S1frame25'>
                            <span>Payment Due Today (tax not included):</span>
                            <div className='S1amount'>{currencyIcon}{calculateTotalCost()}</div>
                        </div>
                        {/* <div className='S1frame26' onClick={onAction}> */}
                        <div className='S1frame26' onClick={handleNext}>
                            <span>Next</span>
                            <img src={buynow} />
                        </div>
                    </div>


                </div>
            </div>
        </div>

    );
};

export default RenewSubscriptionForm;
