import React, { useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import S3PaymentModal from "../PurchaceFlow/S3PaymentModel";
import "../AddLicences/AddLicenses.css";
import { handlePayment } from "../razorpay";
import axios from 'axios';


const AddSubscriptionReviewPlaceOrder = ({ onAction, subscriptionData }) => {
  console.log(
    "AddSubscriptionReviewPlaceOrder_subscriptionData",
    subscriptionData
  );
  const [showPopup, setShowPopup] = useState(false);
  const [showRetryButton, setShowRetryButton] = useState(false);
  const [captureDetails, setCaptureDetails] = useState(null);
  const [retryUrl, setRetryUrl] = useState('');
  const taxRate = subscriptionData.subConsole === "US" ? 0 : 0.18;
  const [subtotal, setSubtotal] = useState(subscriptionData.totalAmount);
  const [taxAmount, setTaxAmount] = useState(0);
  const [totalWithTax, setTotalWithTax] = useState(0);
  const [razorpayId, setRazorpayId] = useState('');

  const token = localStorage.getItem('token');
  const user = localStorage.getItem('userId');

  useEffect(() => {
    const calculatedTax = parseFloat(subtotal) * taxRate;
    const calculatedTotalWithTax = Math.round(parseFloat(subtotal) + calculatedTax);
    
    setTaxAmount(calculatedTax.toFixed(2)); 
    setTotalWithTax(calculatedTotalWithTax); 
  }, [subtotal, taxRate]);

  useEffect(() => {
    const fetchRazorpay = async () => {
        const response = await fetch('/get/stage', {
            method:'GET',
            headers:{
                'Authorization': `${token}`,
            },
        });
        const data = await response.json();
        console.log(data)
        setRazorpayId(data.razorpay)
    }
    fetchRazorpay()
  },[])

  console.log("saad", subscriptionData);
  const {
    currency,
    count,
    costPerUser,
    totalAmount,
    itemName,
    billingTerm,
    actualBillingTerm,
    customerAddress,
    subConsole,
    subscriptionId,
  } = subscriptionData;
 
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handlenextpage = () => {
    navigate("/stage4");
    window.scrollTo(0, 0);
  };

  const handlePlaceOrderClick = () => {
    subscriptionData.taxRate = taxRate;
    subscriptionData.taxAmount = taxAmount;
    subscriptionData.totalAmount = totalWithTax;
    subscriptionData.totalAmountWithOutTax = totalAmount;
    subscriptionData.name = customerAddress["first_name"] + " " + customerAddress["last_name"];
    subscriptionData.email = customerAddress["email"];
    subscriptionData.phone = customerAddress["phone"];
    subscriptionData.quantity = count;
    subscriptionData.console = subConsole;
    subscriptionData.id = subscriptionId;
    subscriptionData.currency = currency;
    subscriptionData.userId = user;
    // setLoading(true)
    handlePayment(
      handlePaymentSuccess,
      handlePaymentFailure,
      "buyMore",
      subscriptionData,
      setLoading,
      razorpayId
    );
  };
  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handlePaymentSuccess = (response) => {
    onAction();
  };

  const handlePaymentFailure = (details, message, url) => {
    setLoading(false)
    console.error(message);
    setCaptureDetails(details);
    setRetryUrl(url);
    setShowRetryButton(true);
  };


  const handleRetryClick = async () => {
    if (captureDetails) {
      setLoading(true);
      try {
        const result = await axios.post(retryUrl, captureDetails);
        if (result.data.status) {
          onAction();
          setLoading(false);
        } else {
          console.error('Retry failed: Backend validation failed.');
          setLoading(false);
        }
      } catch (error) {
        console.error('Retry failed:', error);
        setLoading(false);
      }
    }
  };

  return (
    <div className="S3main-content">
      {loading && (
        <div className="modal-overlay">
          <div className="preloader-modal">
            <div className="spinner"></div>
          </div>
        </div>
      )}
      <div className="S3frame-12">
        <div className='AddSubForm-LPServicesHeading'>
            <span>Review and Place Order</span>
        </div>
        <p className="S3check-fields">
          It is wise to check every field before placing the order.
        </p>
        <div className="S3Fields">
          <div className="S3Frame">
            <div className="S3Frame1 columnFlex">
              <div className="S3Framecolumn">
                <span className="S3Label">Product Name:</span>
                <span className="S3Value">{itemName}</span>
              </div>
              <div className="S3Framecolumn">
                <span className="S3Label">Billing Commitment:</span>
                <span className="S3Value">{billingTerm}</span>
              </div>
            </div>
            <div className="S3Frame1 columnFlex">
              <div className="S3Framecolumn">
                <span className="S3Label">Price:</span>
                <span className="S3Value">
                  {currency}
                  {costPerUser}
                </span>
              </div>
              <div className="S3Framecolumn">
                <span className="S3Label">Quantity:</span>
                <span className="S3Value">X{count}</span>
              </div>
            </div>
            <div className="S3Frame1 columnFlex">
              <div className="S3Framecolumn">
                <span className="S3Label">Billing Plan:</span>
                <span className="S3Value">{actualBillingTerm}</span>
              </div>
              <div className="S3Framecolumn">
                <span className="S3Label">Subtotal:</span>
                <span className="S3Value">
                  {currency}
                  {subtotal}(tax not included)
                </span>
              </div>
            </div>
          </div>

          <div className="AddSubPlOd-S3Frame AddSubPlOd-S3FrameMiddle">
            <div className="AddSubPlOd-S3Frame1">
              <div className="AddSubPlOd-S3Framecolumn">
                <span className="AddSubPlOd-S3Label">Sold to Address:</span>
                <span className="AddSubPlOd-S3Value">
                  <p>{customerAddress.add1}</p>
                  <p>{customerAddress.add2}</p>
                  <p>
                    {customerAddress.city}, {customerAddress.state},{" "}
                    {customerAddress.pincode}, {customerAddress.country}
                  </p>
                  <p>{customerAddress.gst_code}</p>
                </span>
              </div>
            </div>
          </div>
          {/* <div className='S3Frame'>
              <div className="S3Frame1">
                  <div className="S3Framecolumn">
                    <span className="S3Value">
                    <p>When your subscription starts, we'll charge you <b>{currency}{totalAmount}/{actualBillingTerm}</b> plus applicable taxes every month. Charges will be included 
                    on the first invoice after the subscription starts. You'll be notified before any price changes. Cancel within 7 days of purchase for a prorated refund by visiting your Brio CSP account. Refunds aren't available after 7 days.
                    <a href="https://learn.microsoft.com/en-us/microsoft-365/commerce/subscriptions/cancel-your-subscription?view=o365-worldwide"target="_blank" rel="noopener noreferrer">Learn more about cancelling</a>.</p><br />
                    <p>Read our <a href="https://privacy.microsoft.com/en-us/privacystatement"target="_blank" rel="noopener noreferrer">privacy statement</a> for information on how your data is handled. By selecting accept agreement & place order, you agree to the terms and conditions in the <a href="https://www.microsoft.com/licensing/docs/customeragreement"target="_blank" rel="noopener noreferrer">Microsoft Customer Agreement</a>.</p><br />
                    <p>New Global, Billing, and Reader administrators will be granted permission to the Brio Billing account. You can disable the permissions after sign-up.</p>
                    </span>
                  </div>
              </div>
          </div> */}
          <div className="S3Frame">
            <div className="S3Frame1 rowFlex">
              {/* <div className="S3Framecolumn"> */}
              <span className="S3Label">
                {actualBillingTerm} commitment, billed monthly (before tax):
              </span>
              {/* </div> */}
              <div className="S3FrameAmount">
                <span>
                  {currency}
                  {subtotal}
                </span>
              </div>
            </div>
            {taxRate > 0 && (
              <div className="S3Frame1 rowFlex">
                <span className="S3Label">Estimate taxes:</span>
                <div className="S3FrameAmount">
                  <span>
                    {currency}
                    {taxAmount}
                  </span>
                </div>
              </div>
            )}
            <div className="S3Frame1 rowFlex">
              {/* <div className="S3Framecolumn"> */}
              <span className="S3Label">Total({actualBillingTerm}):</span>
              {/* </div> */}
              <div className="S3FrameAmount">
                <span>
                  {currency}
                  {totalWithTax}
                </span>
              </div>
            </div>
          </div>
          <div className="AddSubPlOd-S3Footer">
            <div className="AddSubPlOd-S3FooterContent">
              <span>
                {showRetryButton
                  ? "Your payment has been completed. Please click on Submit to complete your order."
                  : "By placing an order, you are accepting our terms and conditions."
                }
              </span>
              {showRetryButton ? (
                <div onClick={handleRetryClick}>
                  <button className="AddSubPlOd-S3PlaceOrderButton">
                    Submit
                  </button>
                </div>
              ) : (
                <div onClick={handlePlaceOrderClick}>
                  <button className="AddSubPlOd-S3PlaceOrderButton">
                    Place Order
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {showPopup && (
        <div className="AddSubPlOd-S3popup-overlay">
          <S3PaymentModal close={handleClosePopup} />
        </div>
      )}
    </div>
  );
};
export default AddSubscriptionReviewPlaceOrder;
